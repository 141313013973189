import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    InputGroup,
    InputLeftElement,
    Input,
    chakra,
    InputRightElement,
    Heading,
} from '@chakra-ui/react';
import ReactFlow, { useNodesState, useEdgesState, addEdge } from 'reactflow';
// Chakra imports
import {

    Grid,
    Image,


    Thead,
} from "@chakra-ui/react";
// assets
import fig1 from "../assets/Figure_1.png"
import fig2 from "../assets/Figure_2.png"

import { Table, Tbody, Tr, Td } from '@chakra-ui/react';

import 'reactflow/dist/style.css';


import { FaSearch } from "react-icons/fa";
import { useCallback, useState } from 'react';
const CiSearchT = chakra(FaSearch);
export default function AIFunction() {
    const initialNodes = [
        { id: '1', position: { x: 50, y: 30 }, data: { label: 'Submit registration key' }, style: { fontSize: "12px" }, },
        { id: '2', position: { x: 50, y: 170 }, data: { label: 'Provide email & password' }, style: { fontSize: "12px" }, },
        { id: '3', position: { x: 50, y: 270 }, data: { label: 'Email confirmation' }, style: { fontSize: "12px" }, },
        { id: '4', position: { x: 50, y: 350 }, data: { label: 'provide the booking system key' }, style: { fontSize: "12px" }, },
        { id: '5', position: { x: 50, y: 450 }, data: { label: 'Finalize account creation' }, style: { fontSize: "12px" }, },
        // 
        { id: '10', position: { x: 270, y: 100 }, data: { label: 'Validate the key' }, style: { fontSize: "12px" }, },
    ];
    const initialEdges = [
        // { id: 'e1-2', source: '1', target: '2', },
        // { id: 'e2-3', source: '1', target: '2' },
        // { id: 'e3-4', source: '1', target: '2' },
        { id: 'e4-5', source: '4', target: '5' },
        { id: 'e10', source: '1', target: '10' },
        { id: 'e12', source: '10', target: '2' },
        { id: 'e20', source: '2', target: '3' },
        { id: 'e21', source: '3', target: '4' }
    ];
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [setEdges],
    );



    const iconBoxInside = useColorModeValue("white", "white");

    const tableData = [
        ['Feature', 'Description'],
        ['salon_id', 'Saloon Id'],
        ['week_day', 'Day in the week (eg. Monday)'],
        ['is_weekend', 'True if the day is a weekend'],
        ['is_holiday', 'True if the day is a holiday'],
        ['season', 'Season (e.g. Winter)'],
        ['Total_bookings', 'Total number of bookings for the salon in the selected parameters.'],
    ];
    const tableData2 = [
        ['Feature', 'Description'],
        ['salon_id', 'Saloon Id'],
        ['date_year', 'Year'],
        ['date_month', 'Month'],
        ['date_day', 'Day'],
        ['week_day', 'Day in the week (eg. Monday)'],
        ['is_weekend', 'True if the day is a weekend'],
        ['is_holiday', 'True if the day is a holiday'],
        ['season', 'Season (e.g. Winter)'],
        ['is_sunny', 'True if it is a sunny day'],
        ['is_cloudy', 'True if it is a cloudy day'],
        ['is_rainy', 'True if it is a rainy day'],
        ['is_snowy', 'True if it is a snowy day'],
        ['Total_bookings', 'Total number of bookings for the salon in the mentioned date (Year-Month-Day)'],
    ];
    const tableData3 = [
        ['Model', 'Test on 406 records', '', 'Test on 812 records', ''],
        ['', 'MSE', 'Difference', 'MSE', 'Difference'],
        ['catBoost', '8.43', '±2.27', '9.08', '±2.38'],
        ['Gradient Boosting', '8.45', '±2.28', '9.09', '±2.38'],
        ['XGBoost', '8.59', '±2.29', '9.11', '±2.38'],
        ['Decision Trees', '8.59', '±2.28', '9.12', '±2.38'],
        ['Random Forest', '8.54', '±2.28', '9.12', '±2.39'],
        ['Linear Regression', '11.93', '±2.80', '12.63', '±2.85'],
    ];
    const tableData4 = [
        ['Feature', 'Description'],
        ['salon_id', 'Saloon Id'],
        ['model', 'The regression model that will be used to the prediction'],
        ['week_day', 'Day in the week (eg. Monday)'],
        ['is_weekend', 'True if the day is a weekend'],
        ['is_holiday', 'True if the day is a holiday'],
        ['season', 'Season (e.g. Winter)'],
    ];


    const [showInfosMobile, setShowInfosMobile] = useState(false)
    const [isActiveSection, setIsActiveSection] = useState("introduction")

    const handleClickScroll = (section) => {
        const element = document.getElementById(section);
        setIsActiveSection(section)
        console.log(element)
        setShowInfosMobile(true)
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({
                behavior: 'smooth', block: "start",
                inline: 'nearest'
            });
        }
    };
    const numRows = 17;
    const numCols = 4;
    const numRowsClient = 11;
    const numColsClient = 4;
    const numRowsCleints2 = 6;
    const numColsClients2 = 2;
    const groupRows = 4; // Number of rows to group in the first column

    return (
        <Flex>
            <Box p={"3rem"} width={"80%"} pt={"1.5rem"} >
                <Box >Business > AI Documentation</Box>
                <Box fontSize={"1.5rem"} fontWeight={"bold"} mt={"1.5rem"} >AI Documentation</Box>
                <Text mb={6} borderBottom={"1px solid #d3d3d3"} pb={"18px"}>
                    A description of the research and development process of an AI model for predicting booking demands
                </Text>
                <Box>
                    {/* Step 1: Introduction */}
                    <Text fontWeight="bold" mb={4}>1. Introduction</Text>
                    <Text mb={4}>
                        In today's data-driven world, harnessing the power of Artificial Intelligence (AI) for predictive tasks is becoming increasingly important. Predictive modeling with AI enables us to anticipate future trends, make informed decisions, and optimize various aspects of business operations. One such area where prediction can be particularly valuable is in forecasting salon booking demand.
                        <br />
                        Prediction with AI involves using historical data to build models that can forecast future outcomes. By doing so, we gain and offer valuable insights into trends, patterns, and customer behavior, which can help us make better-informed decisions. In the context of salon businesses, predicting booking demand allows for more efficient staff scheduling, resource allocation, and improved customer service.
                    </Text>

                    {/* Step 2: Why predictive modelling */}
                    <Text fontWeight="bold" size="md" mb={4}>2. Why predictive modelling</Text>
                    <Text mb={4}>
                        <p style={{ paddingTop: "0.5rem" }}>In the dynamic landscape of data-driven decision-making, predictive
                            modeling stands as a formidable tool with the potential to revolutionize how
                            businesses operate. It offers a window into the future by harnessing the
                            power of data and machine learning algorithms to anticipate and forecast
                            outcomes.</p>
                        <p style={{ paddingTop: "0.5rem" }}>
                            Among the limitless applications of predictive modelling, our business
                            clients can benefit from AI for the following raisons:
                            <ul style={{ paddingLeft: "1.1rem" }}>
                                <li>
                                    <strong>Plan Staff Scheduling: </strong> Our business client input the dates of the
                                    planned promotions. By analyzing the booking demand
                                    predictions for these consecutive dates, our AI model provides
                                    him with a clear picture of when the salon is expected to be
                                    exceptionally busy. Armed with this insight, our clients can
                                    schedule more staff during these high-demand periods to
                                    ensure prompt and efficient service.
                                </li>
                                <li>
                                    <strong>Inventory and Resource Management: </strong> In addition to staff
                                    scheduling, the predictive modelling also helps our clients
                                    estimate the need for salon supplies and equipment. For
                                    instance, if promotions involve special hair treatments, knowing the expected demand enables to stock up on the required
                                    products.
                                </li>

                                <li>
                                    <strong>Marketing Strategies: </strong>  Our clients can align their marketing
                                    efforts with the predicted high-demand periods. For example,
                                    clients might run targeted advertising campaigns or offer
                                    promotions specifically on days when the salon is expected to be
                                    at its busiest, attracting even more customers.
                                </li>

                                <li>
                                    <strong>Enhanced Customer Experience: </strong>  With optimized resource
                                    allocation, clients can provide a better customer experience.
                                    Customers won't have to wait as long, and their staff can focus
                                    on delivering quality service.
                                </li>
                            </ul>
                        </p>

                    </Text>

                    {/* Step 3: Building dataset */}
                    <Text fontWeight="bold" size="md" mb={4}>3. Building dataset</Text>
                    <Text mb={4}>
                        <p style={{ paddingTop: "0.5rem" }}>In the absence of readily available training data that aligns with our
                            specific requirements, we embarked on the task of creating our own dataset
                            tailored to our salon booking prediction project. This custom dataset serves
                            as the foundation for testing, training, and fine-tuning our AI model. During
                            the dataset creation process, we meticulously logged all pertinent
                            information related to salon bookings.</p>
                        <p style={{ paddingTop: "0.5rem" }}>
                            Our dataset for now includes synthetically generated booking data.
                            These synthetic bookings are generated following a predefined pattern. The
                            primary objective behind incorporating such synthetic data is to evaluate
                            the model's capability to discern and accurately predict bookings that
                            conform to this specific pattern.
                        </p>
                        <p style={{ paddingTop: "0.5rem" }}>Furthermore, by combining synthetic data, we aim to create a
                            comprehensive and diverse dataset that challenges our AI model to
                            identifying and accurately predicting bookings that align with the
                            predetermined pattern. This approach allows us to thoroughly test the
                            model's predictive abilities and assess its robustness in handling various
                            booking scenarios.</p>
                        <p style={{ paddingTop: "0.5rem" }}>In the following sections, we will delve into the details of this dataset,
                            highlighting its composition, structure, and the specific features captured,
                            which will be essential for the training, testing, and validation of our salon
                            booking demand prediction model.</p>                    </Text>

                    {/* Step 4: Features */}
                    <Text fontWeight="bold" size="md" mb={4}>4. Features</Text>
                    <Text mb={4}>
                        <p style={{ paddingTop: "0.5rem" }}>Our dataset comprehensively captures various aspects of booking
                            information, encompassing details such as date, weather conditions, and
                            service specifics. Furthermore, it delves into the behavior of the key parties
                            involved, including clients, salons, and staff.</p>
                        <p style={{ paddingTop: "0.5rem" }}>
                            Currently, a portion of our dataset is dedicated to predicting booking
                            demand for salons (see Table 4-2), and the actual dataset for this specific
                            application is expanded in case we need to (see ).
                        </p>
                        <p style={{ paddingTop: "0.5rem" }}>However, our extensive dataset is designed to facilitate even more
                            intricate and complex predictions, such as client behavior. These predictions
                            draw from:

                            <ul style={{ paddingLeft: "1.1rem" }}>
                                <li>
                                    <strong>Client-related Information: </strong>  Encompassing data and features
                                    that describe client behavior and actions, such as bookings,
                                    cancellations, reviews, and more.
                                </li>
                                <li>
                                    <strong>Salon-related Information: </strong> Reflecting salon behavior and
                                    statistics, including bookings, cancellations, reviews, and other
                                    relevant details.
                                </li>

                                <li>
                                    <strong>Weather-related Information: </strong>   Incorporating weather-related
                                    data associated with all events and actions.
                                </li>

                                <li>
                                    <strong>Date-related Information: </strong>   Comprising information pertaining to
                                    dates, weekdays, weekends, and holidays.
                                </li>
                            </ul>


                            <Box margin={"auto"} width={"fit-content"} marginTop={"0.6rem"} fontWeight={"500"}>
                                <Table size="sm" borderWidth="2px" borderColor="gray.500">
                                    <Tbody>
                                        {Array.from({ length: 7 }).map((_, rowIndex) => (
                                            <Tr key={rowIndex}>
                                                {Array.from({ length: 2 }).map((_, colIndex) => (
                                                    <Td
                                                        key={colIndex}
                                                        borderWidth="1px"
                                                        borderColor="gray.500"
                                                        p="3"
                                                        textAlign={"center"}
                                                    >
                                                        {tableData[rowIndex][colIndex]}
                                                    </Td>
                                                ))}
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                                <Text mt="2" fontSize="sm" fontWeight="bold" color={"gray.500"} margin={"auto"}
                                    width={"fit-content"}>
                                    Table 4-1 Dictionary for dataset features used for our current prediction booking demand
                                    model.                      </Text>
                            </Box>

                            <Box margin={"auto"} width={"fit-content"} marginTop={"0.6rem"} fontWeight={"500"}>
                                <Table size="sm" borderWidth="2px" borderColor="gray.500">
                                    <Tbody>
                                        {Array.from({ length: 14 }).map((_, rowIndex) => (
                                            <Tr key={rowIndex}>
                                                {Array.from({ length: 2 }).map((_, colIndex) => (
                                                    <Td
                                                        key={colIndex}
                                                        borderWidth="1px"
                                                        borderColor="gray.500"
                                                        p="3"
                                                        textAlign={"center"}
                                                    >
                                                        {tableData2[rowIndex][colIndex]}
                                                    </Td>
                                                ))}
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                                <Text mt="2" fontSize="sm" fontWeight="bold" color={"gray.500"} margin={"auto"}
                                    width={"fit-content"}>
                                    Table 4-2 Dictionary for the extended dataset features needed for predicting booking demand
                                    for salons.                     </Text>
                            </Box>
                        </p>
                        <p style={{ paddingTop: "0.5rem" }}>
                            Throughout this document, our primary focus will be on the
                            development and deployment of a prediction model for booking demand.
                            This model leverages the features presented in the table above to provide
                            valuable insights into salon booking trends and patterns.
                        </p>                    </Text>

                    {/* Step 5: Research and Development of our AI model */}
                    <Text fontWeight="bold" size="md" mb={4}>5. Research and Development of our AI model</Text>
                    <Text mb={4}>
                        <p style={{ paddingTop: "0.5rem" }}>In this section, we delve into the process of developing, testing, and
                            comparing multiple regression models to predict booking demand—a
                            crucial insight that holds significant importance for our business clients.
                            Accurate demand prediction enhances operational efficiency by facilitating
                            optimized staff scheduling, resource allocation, and ultimately, improved
                            customer service.</p>

                        <p style={{ paddingTop: "0.5rem" }}>Our pursuit of an effective prediction model led us to explore various
                            approaches, ultimately leading to the decision to implement and evaluate
                            three well-established regression models:
                            <ul style={{ paddingLeft: "1.1rem" }}>
                                <li>
                                    <strong>CatBoost: </strong>  A gradient boosting algorithm designed for
                                    categorical feature support.
                                </li>
                                <li>
                                    <strong>Decision Trees: </strong>  A fundamental yet powerful model for
                                    classification and regression tasks.
                                </li>

                                <li>
                                    <strong>Linear Regression: </strong>    A simple yet interpretable model for
                                    predicting numeric values.
                                </li>

                                <li>
                                    <strong>XGBoost: </strong>  An efficient and scalable gradient boosting framework.
                                </li>
                                <li>
                                    <strong>Gradient Boosting: </strong> A machine learning ensemble method for
                                    regression and classification problems.
                                </li>
                                <li>
                                    <strong>Random Forest: </strong> An ensemble machine learning technique that
                                    combines multiple decision trees to improve predictive accuracy
                                    and reduce overfitting.
                                </li>
                            </ul>

                        </p>

                        <p style={{ paddingTop: "0.5rem" }}>In our pursuit of developing the most accurate AI model possible, we
                            subjected all regression models to rigorous testing across multiple
                            scenarios for predicting booking demands. These scenarios
                            encompass:
                            <ul style={{ paddingLeft: "1.1rem" }}>
                                <li>
                                    <strong>Looking-Up Specific Dates:  </strong>  This functionality caters to the
                                    immediate needs of our business clients. It enables them to
                                    predict booking demand for their services on specific dates,
                                    offering valuable insights to better manage their resources.
                                </li>
                                <li>
                                    <strong>Looking-Up Consecutive Dates: </strong>  Our clients benefit from this
                                    feature, which provides insights into the demand for services in
                                    the near future. This invaluable insight empowers them to devise
                                    strategies for increasing productivity and income, enabling
                                    proactive decision-making to meet customer demands
                                    efficiently.
                                </li>

                            </ul>
                            Through testing in these diverse scenarios, we aim to optimize
                            the accuracy and utility of our AI model, ultimately empowering our clients
                            to make data-driven decisions that enhance the efficiency and profitability
                            of their salon operations.

                        </p>


                        <p style={{ paddingTop: "0.5rem" }}>In this section, we turn our attention to the performance evaluation of
                            each regression model. Our analysis centers on two fundamental metrics:
                            <ul style={{ paddingLeft: "1.1rem" }}>
                                <li>
                                    <strong>Mean Squared Error (MSE):  </strong> MSE quantifies the average squared
                                    difference between the predicted values and the expected
                                    values in our test datasets. A lower MSE signifies a model's ability
                                    to make more accurate predictions.
                                </li>
                                <li>
                                    <strong>Difference between Predicted and Expected Values: </strong>  We also
                                    examine the difference between the predicted and expected
                                    values
                                </li>

                            </ul>
                            Test are conducted on two datasets, the first dataset comprises 406
                            test records, while the second comprises 812 test records. This assessment
                            allows us to gauge the model's consistency and precision across different
                            data volumes. For test results, check Table 5-1.

                            <Box margin={"auto"} width={"fit-content"} marginTop={"0.6rem"} fontWeight={"500"}>
                                <Table size="sm" borderWidth="1px" borderColor="gray.300">
                                    <Tbody>
                                        {Array.from({ length: 8 }).map((_, rowIndex) => (
                                            <Tr key={rowIndex}>
                                                {Array.from({ length: 5 }).map((_, colIndex) => (
                                                    ((colIndex % 2 != 1 && rowIndex == 0 && colIndex != 0) || (colIndex == 0 && rowIndex == 1)) ? null : (
                                                        <Td
                                                            key={colIndex}
                                                            borderWidth="1px"
                                                            borderColor="gray.300"
                                                            p="3"
                                                            align="center" // Center the content horizontally
                                                            justify="center" Center the content vertically
                                                            colSpan={colIndex % 2 === 1 && rowIndex === 0 ? 2 : 1}
                                                            rowSpan={colIndex == 0 && rowIndex == 0 ? 2 : 1}
                                                            textAlign={"center"}
                                                        >
                                                            {tableData3[rowIndex][colIndex]}
                                                        </Td>)
                                                ))}
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>


                                <Text mt="2" fontSize="sm" fontWeight="bold" color={"gray.500"} margin={"auto"}
                                    width={"fit-content"}>
                                    Table 5-1 Test results for six different regression models on two test datasets.
                                </Text>
                            </Box>


                            <Box marginBottom={"1.2rem"}>
                                <Image
                                    src={fig1}
                                    alt="Description of the image"
                                    w={"80%"}
                                    margin={"auto"}
                                    marginTop={"0.8rem"} />
                                <Text mt="2" fontSize="sm" fontWeight="bold" color={"gray.500"} margin={"auto"}
                                    width={"fit-content"}>
                                    Figure 1 change in the predected booking demands on Augest 2023
                                </Text>
                                <Image
                                    src={fig2}
                                    alt="Description of the image"
                                    w={"80%"}
                                    margin={"auto"}
                                    marginTop={"0.8rem"}
                                />
                                <Text mt="2" fontSize="sm" fontWeight="bold" color={"gray.500"} margin={"auto"}
                                    width={"fit-content"}>
                                    Figure 2 change in the difference between the predicted values and the actual values
                                </Text>
                            </Box>
                            Test results show that all first five regression models have very similar
                            performance and accuracy, with an average MSE value of 9.11, and an
                            average difference (error margin) of ±2.38 meaning that on average the
                            predicted value is 2.38 bookings away (more or less) from the expected
                            value, which is very acceptable.
                        </p>


                        <p style={{ paddingTop: "0.5rem" }}>
                            Throughout this document, our primary focus will be on the
                            development and deployment of a prediction model for booking demand.
                            This model leverages the features presented in the table above to provide
                            valuable insights into salon booking trends and patterns.
                        </p>
                    </Text>

                    {/* Step 6: Deployment to business dashboard */}
                    <Text fontWeight="bold" size="md" mb={4}>6. Deployment to business dashboard</Text>
                    <Text mb={4}>
                    <p style={{ paddingTop: "0.5rem" }}>For our deployment strategy, we have chosen to utilize a Python server
              powered by Flask and Pydantic.</p>
            <p style={{ paddingTop: "0.5rem" }}>
              Our current server configuration is centered around a single route,
              exclusively designed to handle GET requests originating from our business
              clients' dashboards. The request parameters align precisely with the
              definitions outlined in Table 6-1.
            </p>
            <p style={{ paddingTop: "0.5rem" }}>
              <Box margin={"auto"} width={"fit-content"} marginTop={"0.6rem"} fontWeight={"500"}>
                <Table size="sm" borderWidth="2px" borderColor="gray.500">
                  <Tbody>
                    {Array.from({ length: 7 }).map((_, rowIndex) => (
                      <Tr key={rowIndex}>
                        {Array.from({ length: 2 }).map((_, colIndex) => (
                          <Td
                            key={colIndex}
                            borderWidth="1px"
                            borderColor="gray.500"
                            p="3"
                            textAlign={"center"}
                          >
                            {tableData4[rowIndex][colIndex]}
                          </Td>
                        ))}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <Text mt="2" fontSize="sm" fontWeight="bold" color={"gray.500"} margin={"auto"}
                  width={"fit-content"}>
                  Table 6-1 Dictionary for dataset features used for our current prediction booking demand
                  model.                   </Text>
              </Box>
            </p>
            <p style={{ paddingTop: "0.5rem" }}>
              Within this system, the prediction route takes charge of processing
              incoming requests. If all provided parameters are validated successfully, it
              proceeds to generate and return the predicted booking numbers based on
              the selected input parameters. However, in cases where any of the
              parameters fail to meet the validation criteria, the server will respond with a

              concise and informative error message, appropriately signaling the issue
              with a status code 400.
            </p>
                    </Text>

                    {/* Step 7: Conclusion */}
                    <Text fontWeight="bold" size="md" mb={4}>7. Conclusion</Text>
                    <Text mb={4}>
                    <p style={{ paddingTop: "0.5rem" }}>In conclusion, our journey through this documentation has illuminated
              the significance of predictive modeling, particularly in the context of salon
              booking demand prediction. We have explored the development and
              deployment of a powerful AI model that leverages various regression
              techniques, such as CatBoost, XGBoost, and Decision Trees, to provide
              invaluable insights into booking trends and patterns.</p>
            <p style={{ paddingTop: "0.5rem" }}>
              Our dataset, meticulously crafted to encompass all aspects of booking
              information, empowers businesses to make data-driven decisions with a
              profound impact. Whether it's predicting demand for specific dates or
              analyzing consecutive dates for resource optimization, our AI model
              facilitates efficient staff scheduling, resource allocation, and enhanced
              customer service.
            </p>
            <p style={{ paddingTop: "0.5rem" }}>Through rigorous testing and evaluation, we have examined the
              performance of each model, considering metrics like Mean Squared Error
              (MSE) and predictive accuracy across diverse datasets. This meticulous
              assessment ensures that our clients can confidently rely on our AI model to
              guide their operational decisions.</p>


            <p style={{ paddingTop: "0.5rem" }}>In embracing the power of predictive modeling, we enable businesses
              to not only meet the demands of today but also anticipate those of
              tomorrow, ultimately driving efficiency, profitability, and customer
              satisfaction to new heights in the dynamic world of salon operations.</p>
                    </Text>
                </Box>


            </Box>
            <Flex width={"20%"} position={"sticky"} top={"8vh"} py={"4rem"} height={"91vh"} flexDirection={"column"}>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>In this page</Box>
                <Box fontSize={"0.9rem"} mb={3}>Introduction</Box>
                <Box fontSize={"0.9rem"} mb={3}>Why predictive modelling</Box>
                <Box fontSize={"0.9rem"} mb={3}>Building dataset</Box>
                <Box fontSize={"0.9rem"} mb={3}>Features</Box>
                <Box fontSize={"0.9rem"} mb={3}>Research and Development</Box>
                <Box fontSize={"0.9rem"} mb={3}>Deployment</Box>
                <Box fontSize={"0.9rem"} mb={3}>Conclusion</Box>
            </Flex>
        </Flex>

    );
}



