import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    InputGroup,
    InputLeftElement,
    Input,
    chakra,
    InputRightElement,
    Heading,
} from '@chakra-ui/react';
import ReactFlow, { useNodesState, useEdgesState, addEdge } from 'reactflow';
import { MarkerType, Position } from 'reactflow';
import 'reactflow/dist/style.css';


import { FaSearch } from "react-icons/fa";
import { useCallback, useState } from 'react';
const CiSearchT = chakra(FaSearch);
export default function EditProfile() {

    const initialNodes00 = [
        { id: '00', position: { x: 380, y: 30 }, sourcePosition: 'bottom', data: { label: 'Configuration' }, style: { fontSize: "14px", background: "#008080", fontFamily: "cursive", color: "white" }, },

        { id: '1', position: { x: 180, y: 140 }, data: { label: 'Edit Profile' }, style: { fontSize: "14px", background: "#66b2b2", fontFamily: "cursive", color: "white" }, },
        { id: '2', position: { x: 2, y: 310 }, targetPosition: 'top', data: { label: 'Edit Location' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '3', position: { x: 160, y: 310 }, targetPosition: 'top', data: { label: 'Edit General Infos' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '6', position: { x: 80, y: 220 }, targetPosition: 'top', data: { label: 'Edit General' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '7', position: { x: 270, y: 220 }, targetPosition: 'top', data: { label: ' add Social media' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },

        { id: '8', position: { x: 580, y: 140 }, data: { label: 'Settings' }, style: { fontSize: "14px", background: "#66b2b2", fontFamily: "cursive", color: "white" }, },
        { id: 'e1', position: { x: 460, y: 220 }, sourcePosition: 'bottom', data: { label: 'Notifications' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: 'e2', position: { x: 370, y: 310 }, sourcePosition: 'bottom', targetPosition: 'top', data: { label: 'Select notification to receive' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: 'e3', position: { x: 280, y: 400 }, targetPosition: 'top', data: { label: 'Email notifications' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: 'e4', position: { x: 450, y: 400 }, targetPosition: 'top', data: { label: 'Platform notifications' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: 'e5', position: { x: 560, y: 310 }, targetPosition: 'top', data: { label: 'Desactivate Account' }, style: { fontSize: "14px", background: "#ee6b6e", fontFamily: "cursive" }, },


        { id: 's1', position: { x: 700, y: 220 }, sourcePosition: 'bottom', data: { label: 'Security' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: 's3', position: { x: 790, y: 400 }, targetPosition: 'top', data: { label: 'Password' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: 's4', position: { x: 620, y: 400 }, targetPosition: 'top', data: { label: 'Feedback' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
    ];
    const initialEdges00 = [
        { id: 'e4-d5', source: '00', target: '1', markerEnd: { type: MarkerType.ArrowClosed, }, },
        { id: 'e4-dd5', source: '00', target: '8', markerEnd: { type: MarkerType.ArrowClosed, }, },
        { id: 'e4-sdd5', source: '8', target: 'e1', markerEnd: { type: MarkerType.ArrowClosed, }, },
        { id: 'e4-dsd5', source: '8', target: 's1', markerEnd: { type: MarkerType.ArrowClosed, }, },
        { id: 'e4-5', source: '1', target: '6', markerEnd: { type: MarkerType.ArrowClosed, }, },
        { id: 'e77-5', source: '1', target: '7', markerEnd: { type: MarkerType.ArrowClosed, }, },
        { id: 'e20', source: '6', target: '2', markerEnd: { type: MarkerType.ArrowClosed, }, },
        { id: 'e21', source: '6', target: '3', markerEnd: { type: MarkerType.ArrowClosed, }, },

        { id: 'ee4-5', source: 'e1', target: 'e2' },
        { id: 'ee4-5', source: 'e1', target: 'e5' },
        { id: 'ee10', source: 'e2', target: 'e3' },
        { id: 'ee12', source: 'e2', target: 'e4' },

        { id: 'se4-5', source: 's1', target: 's3' },
        { id: 'se4-5', source: 's1', target: 's4' },
    ];
    const [nodes00, setNodes00, onNodesChange00] = useNodesState(initialNodes00);
    const [edges00, setEdges00, onEdgesChange00] = useEdgesState(initialEdges00);

    const onConnect00 = useCallback(
        (params) => setEdges00((eds) => addEdge(params, eds)),
        [setEdges00],
    );

    const initialNodes0 = [
        { id: '1', position: { x: 200, y: 30 }, data: { label: 'Edit Profile' },  style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '2', position: { x: 10, y: 210 }, targetPosition: 'top', data: { label: 'Edit Location' },  style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '3', position: { x: 180, y: 210 }, targetPosition: 'top', data: { label: 'Edit General Infos' },  style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },


        { id: '6', position: { x: 100, y: 120 }, targetPosition: 'top', data: { label: 'Edit General' },  style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '7', position: { x: 310, y: 120 }, targetPosition: 'top', data: { label: ' add Social media' },  style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },

    ];
    const initialEdges0 = [
        { id: 'e4-5', source: '1', target: '6', markerEnd: { type: MarkerType.ArrowClosed, }, },
        { id: 'e77-5', source: '1', target: '7', markerEnd: { type: MarkerType.ArrowClosed, }, },
        { id: 'e20', source: '6', target: '2', markerEnd: { type: MarkerType.ArrowClosed, }, },
        { id: 'e21', source: '6', target: '3', markerEnd: { type: MarkerType.ArrowClosed, }, },
    ];
    const [nodes0, setNodes0, onNodesChange0] = useNodesState(initialNodes0);
    const [edges0, setEdges0, onEdgesChange0] = useEdgesState(initialEdges0);

    const onConnect0 = useCallback(
        (params) => setEdges0((eds) => addEdge(params, eds)),
        [setEdges0],
    );



    const initialNodes = [
        { id: '1', position: { x: 30, y: 160 }, sourcePosition: 'right', data: { label: 'Notifications' },  style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '2', position: { x: 210, y: 50 }, sourcePosition: 'right', targetPosition: 'left', data: { label: 'Select notification to receive' },  style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '3', position: { x: 410, y: 10 }, targetPosition: 'left', data: { label: 'Email notifications' },  style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '4', position: { x: 410, y: 120 }, targetPosition: 'left', data: { label: 'Platform notifications' },  style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '5', position: { x: 210, y: 250 }, targetPosition: 'left', data: { label: 'Desactivate Account' },  style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
    ];
    const initialEdges = [
        { id: 'e4-5', source: '1', target: '2' },
        { id: 'e4-5', source: '1', target: '5' },
        { id: 'e10', source: '2', target: '3' },
        { id: 'e12', source: '2', target: '4' },
        // { id: 'e20', source: '2', target: '3' },
        // { id: 'e21', source: '3', target: '4' }
    ];
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [setEdges],
    );



    const initialNodesS = [
        { id: '1', position: { x: 30, y: 80 }, sourcePosition: 'right', data: { label: 'Security' },  style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '3', position: { x: 410, y: 10 }, targetPosition: 'left', data: { label: 'Change Password' },  style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '4', position: { x: 410, y: 120 }, targetPosition: 'left', data: { label: 'Send Feedback' },  style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
    ];
    const initialEdgesS = [
        { id: 'e4-5', source: '1', target: '3' },
        { id: 'e4-5', source: '1', target: '4' },
    ];
    const [nodesS, setNodesS, onNodesChangeS] = useNodesState(initialNodesS);
    const [edgesS, setEdgesS, onEdgesChangeS] = useEdgesState(initialEdgesS);

    const onConnectS = useCallback(
        (params) => setEdgesS((eds) => addEdge(params, eds)),
        [setEdgesS],
    );

    return (
        <Flex>
            <Box p={"3rem"} width={"80%"} pt={"1.5rem"} >
                <Box >Business > Configuration</Box>
                <Box fontSize={"1.5rem"} fontWeight={"bold"} mt={"1.5rem"} >Configuration</Box>
                <Text mb={6} borderBottom={"1px solid #d3d3d3"} pb={"18px"}>
                    Centralized control for updating information and configuring account settings.
                </Text>
                <Box h={"550px"} w={"100%"} margin={"auto"} border={"solid 2px #d3d3d3"} p={"1rem"} fontSize={"1.2rem"} color={"blue"}>

                    <ReactFlow
                        nodes={nodes00}
                        edges={edges00}
                        onNodesChange={onNodesChange00}
                        onEdgesChange={onEdgesChange00}
                        onConnect={onConnect00}

                    />
                </Box>
                <Text mb={6} borderBottom={"1px solid #d3d3d3"} pb={"18px"}>
                   
                   </Text>
                <Box fontSize={"1.5rem"} fontWeight={"bold"} mt={"1.5rem"} mb={"1.2rem"} >Edit Profile</Box>

                <Text fontWeight={"bold"} mb={4}>Step 1: Overview of Profile Layout</Text>
                <Text mb={4}>
                    Upon accessing the profile page via the sidebar, you'll be greeted with a user-friendly layout designed to streamline the management of your salon's information. The profile page features three distinct cards, each serving a specific purpose to enhance the customization and visibility of your salon's details.
                    <br />
                    At the forefront sits the top card, prominently showcasing the image and name of your esteemed salon. This visual centerpiece sets the tone for your salon's digital presence, offering a welcoming introduction to visitors. Additionally, within this card, you'll find two tabs awaiting exploration: "General" and "Social".
                </Text>

                <Text fontWeight={"bold"} mb={4}>Step 2: General Profile </Text>
                <Text mb={4}>
                    By default, the "General" tab is selected, providing immediate access to vital information regarding your salon. Within this tab, two separate cards facilitate effortless navigation and modification. The first card, positioned on the left, presents essential details such as the salon's name, contact number, and email address. Here, you have the flexibility to modify and update these details as needed, ensuring accuracy and consistency across platforms.
                </Text>
                <Text mb={4}>
                    Adjacent to the informational card lies the second card, revealing a dynamic map with a zoomed-in view of your salon's current location. This interactive map feature empowers you to refine and update the geographical coordinates of your salon with precision. Whether adjusting the marker's position or inputting a new address, this functionality ensures that potential clients can easily locate your salon.
                </Text>


                <Text fontWeight={"bold"} mb={4}>Step 3: Social Media</Text>
                <Text mb={4}>
                    Switching gears, the "Social" tab caters to the integration of your salon's social media presence, fostering enhanced connectivity with your audience. Within this tab, you'll find a streamlined process for adding various social media accounts. Simply select the type of social media platform from a comprehensive list and seamlessly integrate the corresponding links. This feature not only amplifies your salon's online visibility but also encourages engagement and interaction with clients across diverse platforms.
                </Text>

                <Text>
                    In essence, the profile page serves as a centralized hub for managing and enhancing your salon's digital identity. With intuitive navigation, customizable features, and seamless integration of social media, this platform empowers you to elevate your salon's online presence and engage with your clientele on a deeper level.
                </Text>

                <Text fontWeight={"bold"} size="md" textAlign={"center"} mt={"2rem"} pt={"2rem"} borderTop={"solid 1px #d3d3d3"} mb={"1rem"}>Edit Profile flowchart</Text>
                <Box h={"360px"} w={"60%"} margin={"auto"} border={"solid 2px #d3d3d3"} p={"2rem"} fontSize={"1.2rem"} color={"blue"}>
                    <ReactFlow
                        nodes={nodes0}
                        edges={edges0}
                        onNodesChange={onNodesChange0}
                        onEdgesChange={onEdgesChange0}
                        onConnect={onConnect0}
                    />

                </Box>
                <Text mb={6} borderBottom={"1px solid #d3d3d3"} pb={"18px"}>
                   
                </Text>
                <Box fontSize={"1.5rem"} fontWeight={"bold"} mt={"1.5rem"} mb={"1.2rem"} >Settings</Box>
                <Text mb={6}  pb={"18px"}>
                    In the settings page which could be accessed through the settings in the side bar,
                    you will have three tabs, the first one concerning the notifications, security, and integration.
                </Text>
                <Box>


                    {/* Notifications Tab */}
                    <Text fontWeight={"bold"} mb={4}>Notifications</Text>
                    <Text mb={4}>
                        In the notifications tab, you will find checkboxes for different types of notifications, both for email and platform notifications.
                    </Text>
                    <Box marginLeft={4}>
                        {/* Email Notifications */}
                        <Text fontWeight={"bold"} mb={2}>> Email & Platform Notifications</Text>
                        <Text mb={2} pl={4}>
                            For email notifications, you have the following options:
                        </Text>
                        <ul style={{ marginLeft: "34px" }}>
                            <li>Campaign Notifications</li>
                            <li>New Features</li>
                            <li>Payments</li>
                            <li>Notification Thresholds</li>
                            <li>Campaign Milestones</li>
                        </ul>



                        {/* Delete Account Option */}
                        <Text fontWeight={"bold"} mb={2} mt={2}>> Delete Account</Text>
                        <Text mb={4} pl={4}>
                            Additionally, you have the option to delete your account. When you choose to delete your account, it will become invisible but recoverable within the next 60 days. After this period, it will be permanently deleted.
                        </Text>
                        <Text fontWeight={"bold"} size="md" textAlign={"center"} mt={"2rem"} pt={"2rem"} borderTop={"solid 1px #d3d3d3"} mb={"1rem"}>Notification Settings flowchart</Text>

                        <Box h={"400px"} w={"80%"} margin={"auto"} border={"solid 2px #d3d3d3"} p={"2rem"} fontSize={"1.2rem"} color={"blue"}>
                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onConnect={onConnect}


                            />

                        </Box>
                    </Box>
                    {/* Security Tab */}
                    <Text fontWeight={"bold"} mb={4} mt={12}>Security</Text>
                    <Text mb={4}>
                        In the security tab, you will have two options. One for changing your password and the other for sending us feedback or complaints about a problem.
                    </Text>
                    <Text fontWeight={"bold"} size="md" textAlign={"center"} mt={"2rem"} pt={"2rem"} borderTop={"solid 1px #d3d3d3"} mb={"1rem"}>Security flowchart</Text>

                    <Box h={"250px"} w={"80%"} margin={"auto"} border={"solid 2px #d3d3d3"} p={"2rem"} fontSize={"1.2rem"} color={"blue"}>
                        <ReactFlow
                            nodes={nodesS}
                            edges={edgesS}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}


                        />
                    </Box>
                    {/* Integration Tab */}
                    <Text fontWeight={"bold"} mb={4} mt={6}>Integration</Text>
                    <Text mb={4}>
                        Integration options will be available soon.
                    </Text>

                </Box>
            </Box>




            <Flex width={"20%"} position={"sticky"} top={"8vh"} py={"4rem"} height={"91vh"} flexDirection={"column"}>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>In this page</Box>
                <Box fontSize={"0.9rem"} mb={3}>Configuration workflow</Box>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>> Edit Profile</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Overview of Profile Layout</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>General Profile</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Social Media</Box>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>> Settings</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Notifications</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Security</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Integration</Box>

            </Flex>
        </Flex>

    );
}



