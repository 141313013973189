import { useRef, useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
  Text,
  Image,
  useColorModeValue,
  useBreakpointValue
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const SignIn = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const emailRef=useRef()
  const passwordRef=useRef()
  const handleLogin=()=>{
    console.log(emailRef.current.value)
     if(emailRef.current.value=="Bookingheroadmin" && passwordRef.current.value=="2500Valby"){
      navigate('/cards');
     }else{
      setShowError(true)
     }
  }
  const handleShowClick = () => setShowPassword(!showPassword);
  const diagonalLineStyle = {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'linear-gradient(to bottom left, transparent 49.5%, #036c5f 49.5%, #036c5f 51.5%, transparent 50.5%)',
    zIndex: 1,
    width: "100%",
    height: "100%"
  };
  return (
     <Box backgroundColor="#fafafa" w={"100vw"}>
    <Flex
      flexDirection="column"
      width="35vw"
      height="100vh"
      margin={
        "auto"
      }
      
      justifyContent="space-between"
      alignItems="center"

    >
      {/* <Box style={diagonalLineStyle}>

      </Box> */}
      <Stack

        position={"relative"}
        flexDir="column"
        mb={1}
        justifyContent="center"
        alignItems="center"
       w={"100%"}
      >

        <Heading color="teal.500" width={"100%"} textAlign={"left"} fontSize={"1.5em"} mt={"3rem"}>
          <Text
            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
            fontFamily={'heading'}
            color={useColorModeValue('gray.800', 'white')}>
            <Image h={"6vh"} src="https://www.bookinghero.io/assets/Logo/logo.webp" alt="" />
          </Text>
        </Heading>
        <Box w={"100%"} my={"3rem"} position={"relative"} zIndex={99}
          boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
          borderRadius={"8px"}

        >
          <form>
            <Stack
              spacing={4}
              p="0.2rem"
              backgroundColor="white"
              boxShadow="md"
              borderRadius={"8px"}
            >
              <Box w={"80%"} textAlign={"left"} fontSize={"1.4em"} fontWeight={"bold"} margin={"auto"} marginTop={"3rem"} mb={"1rem"}>
                Login
              </Box>
              <FormControl w={"80%"} margin={"auto"} mb={"1.5rem"} >
                <InputGroup >
                  <InputLeftElement
                    h={"3rem"}
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input type="email" border={showError? "red solid 2px":"#cdd6e1 solid 1px"} placeholder="email address" h={"3rem"} ref={emailRef} onChange={()=>setShowError(false)} />
                </InputGroup>
              </FormControl>
              <FormControl w={"80%"} margin={"auto"} mb={"1.5rem"}>
                <InputGroup>
                  <InputLeftElement
                    h={"3rem"}
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    h={"3rem"}
                    ref={passwordRef}
                    onChange={()=>setShowError(false)}
                    border={showError? "red solid 2px":"#cdd6e1 solid 1px"}
                  />
                  <InputRightElement width="4.5rem" h={"3rem"}>
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormHelperText textAlign="right">
                  <Link>forgot password?</Link>
                </FormHelperText>
              </FormControl>
             
                <Button
                w={"80%"} margin={"auto"}
                  h={"3rem"}
                 
                  borderRadius={6}
                  type="submit"
                  variant="solid"
                  colorScheme="teal"
                  mb={"2.5rem"}
                  as={"a"}
                  onClick={handleLogin}
                >
                  Login
                </Button>
              {/* <Flex background={"gray.100"} w={"100%"} h={"4rem"} justifyContent={"center"} alignItems={"center"}>
                New to BookingHero?{" "}
                <Link color="teal.500" href="register" marginLeft={"0.5em"}>
                  Sign Up
                </Link>
              </Flex> */}
            </Stack>
          </form>

        </Box>
        <Box w={"100%"} margin={"auto"} fontSize={"1rem"} textAlign={"justify"} >
      © Bookinghero ApS 2024. All rights reserved. Bookinghero is a registered European Community trademark (No. 018988368), national trademark registration in Denmark (No. VR 2024 00181). We have registered office at Ideal Office, Maglebjergvej 6, 2800 Kongens Lyngby, Copenhagen Denmark, VAT/CVR No. DK 44001136. Bookinghero.io is a website owned and operated by Bookinghero ApS.
      </Box>
      </Stack>
    
    </Flex>
    </Box>
  );
};
export default SignIn;
