import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    InputGroup,
    InputLeftElement,
    Input,
    chakra,
    InputRightElement,
    Heading,
} from '@chakra-ui/react';
import ReactFlow, { useNodesState, useEdgesState, addEdge } from 'reactflow';

import 'reactflow/dist/style.css';


import { FaSearch } from "react-icons/fa";
import { useCallback, useState } from 'react';
const CiSearchT = chakra(FaSearch);
export default function BookingDoc() {
    const initialNodes0 = [
        { id: '000', position: { x: 350, y: 30 }, data: { label: 'Booking An Appointement' }, style: { fontSize: "14px", background: "#008080", fontFamily: "cursive", color: "white" }, },

        { id: '0', position: { x: 50, y: 140 }, data: { label: 'Direct Booking' }, style: { fontSize: "14px", background: "#66b2b2", fontFamily: "cursive", color: "white" }, },
        { id: '1', position: { x: 50, y: 230 }, data: { label: 'Select Service' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '2', position: { x: 50, y: 320 }, data: { label: 'Select Staff' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '3', position: { x: 50, y: 410 }, data: { label: 'Choose Date & time' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '4', position: { x: 50, y: 500 }, data: { label: 'Provide PErsonal Information' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '5', position: { x: 50, y: 620 }, data: { label: 'Finalize Booking' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        // 

        { id: '01', position: { x: 650, y: 140 }, data: { label: 'External Booking' }, style: { fontSize: "14px", background: "#66b2b2", fontFamily: "cursive", color: "white" }, },
        { id: '02', position: { x: 650, y: 240 }, data: { label: 'Redirect To Saloon website' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '05', position: { x: 650, y: 340 }, data: { label: 'Finalize Booking' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
    ];
    const initialEdges0 = [
        { id: 'e1-2', source: '000', target: '01', },
        { id: 'e2-3', source: '000', target: '0' },
        { id: 'e3-4', source: '0', target: '1' },
        { id: 'e4-5', source: '4', target: '5' },
        { id: 'e10', source: '1', target: '2' },
        { id: 'e20', source: '2', target: '3' },
        { id: 'e21', source: '3', target: '4' },

        { id: '0e1-5', source: '01', target: '02' },
        { id: '0e4-5', source: '02', target: '05' },

    ];
    const [nodes0, setNodes0, onNodesChange0] = useNodesState(initialNodes0);
    const [edges0, setEdges0, onEdgesChange0] = useEdgesState(initialEdges0);

    const onConnect0 = useCallback(
        (params) => setEdges0((eds) => addEdge(params, eds)),
        [setEdges0],
    );
    const initialNodes = [

        { id: '1', position: { x: 180, y: 30 }, data: { label: 'Service Selection' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '2', position: { x: 180, y: 120 }, data: { label: 'Choose Staff' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '3', position: { x: 180, y: 210 }, data: { label: 'Choose Time and Date' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '4', position: { x: 180, y: 320 }, data: { label: 'Personal Information' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '5', position: { x: 180, y: 430 }, data: { label: 'Appointement created!' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        
    ];
    const initialEdges = [
        // { id: 'e1-2', source: '1', target: '2', },
        // { id: 'e2-3', source: '1', target: '2' },
        // { id: 'e3-4', source: '1', target: '2' },
        { id: 'e4-5', source: '4', target: '5' },
        { id: 'e10', source: '1', target: '2' },
        { id: 'e20', source: '2', target: '3' },
        { id: 'e21', source: '3', target: '4' }
    ];
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [setEdges],
    );
    const initialNodes2 = [
        { id: '2', position: { x: 80, y: 30 }, data: { label: 'Filling login form' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '5', position: { x: 80, y: 120 }, data: { label: 'Finalize login' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
    ];
    const initialEdges2 = [
        { id: 'e4-5', source: '2', target: '5' },
    ];
    const [nodes2, setNodes2, onNodesChange2] = useNodesState(initialNodes2);
    const [edges2, setEdges2, onEdgesChange2] = useEdgesState(initialEdges2);

    const onConnect2 = useCallback(
        (params) => setEdges2((eds) => addEdge(params, eds)),
        [setEdges2],
    );

    return (
        <Flex>
            <Box p={"3rem"} width={"80%"} pt={"1.5rem"} >
                <Box >Client > Booking</Box>
                <Box fontSize={"1.5rem"} fontWeight={"bold"} mt={"1.5rem"} >Appointement Booking</Box>
                <Text mb={6} borderBottom={"1px solid #d3d3d3"} pb={"18px"}>
                    A step-by-step guide is provided below for Booking an appointement.
                </Text>
                <Box h={"800px"} w={"100%"} margin={"auto"} border={"solid 2px #d3d3d3"} p={"2rem"} fontSize={"1.2rem"} color={"blue"}>

                    <ReactFlow
                        nodes={nodes0}
                        edges={edges0}
                        onNodesChange={onNodesChange0}
                        onEdgesChange={onEdgesChange0}
                        onConnect={onConnect0}

                    />
                </Box>
                <Box>
                    <Box fontSize={"1.5rem"} fontWeight={"bold"} mt={"2.5rem"} >Direct Booking</Box>
                    <Text mb={6} borderBottom="1px solid #d3d3d3" pb="18px">
                    </Text>
                    {/* Step 1: Verify Company Registration */}
                    <Text fontWeight="bold" mb={4}>Description</Text>
                    <Text mb={4}>
                        After clicking the booking button on the salon page, you will be redirected to the booking page. On the right, you will see a card displaying the progress of your appointment and the information selected so far. On the left, you will find different cards representing each step of the process.
                    </Text>

                    {/* Step 2: Confirmation and Validation */}
                    <Text fontWeight="bold" size="md" mb={4}>Step 1: Service Selection</Text>
                    <Text mb={4}>
                        The first step is to choose the service you want from the salon by clicking on its corresponding card, which displays its information (price, description, estimated time, etc.).
                    </Text>

                    {/* Step 3: Booking System Key */}
                    <Text fontWeight="bold" size="md" mb={4}>Step 2: Choose Staff</Text>
                    <Text mb={4}>
                        After selecting the service, click "Next" to proceed to the staff selection step, where you can choose the staff member you prefer to provide the service.
                    </Text>

                    {/* Step 4: Access the App */}
                    <Text fontWeight="bold" size="md" mb={4}>Step 3: Date and Time</Text>
                    <Text mb={4}>
                        The next step is to choose the date and time for the appointment, based on the availability of the staff and the service.
                    </Text>

                    {/* Step 5: Submit Information */}
                    <Text fontWeight="bold" size="md" mb={4}>Step 4: Submit Information</Text>
                    <Text mb={4}>
                        In this step, you will complete your information to finalize the appointment under your name and ensure we can contact you if needed.
                    </Text>

                    <Text>
                        Your appointment has been created and is awaiting confirmation!
                    </Text>

                </Box>
                <Text fontWeight={"bold"} size="md" textAlign={"center"} mt={"2rem"} pt={"2rem"} borderTop={"solid 1px #d3d3d3"} mb={"1rem"}>appointment Booking flowchart</Text>
                <Box h={"600px"} w={"60%"} margin={"auto"} border={"solid 2px #d3d3d3"} p={"2rem"} fontSize={"1.2rem"} color={"blue"}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}


                    />

                </Box>

                <Box fontSize="1.5rem" fontWeight="bold" mt="1.5rem">External Booking</Box>
                <Text mb={6} borderBottom="1px solid #d3d3d3" pb="18px">
                </Text>
                <Box>

                    <Text mb={4}>
                        If the salon is not registered in our booking system, direct booking on our platform will not be available. When you click the Booking button, you will be redirected to the salon's website where you can follow their instructions to book an appointment. Alternatively, you can contact them using the information provided on their page on our platform.
                    </Text>


                </Box>



            </Box>
            <Flex width={"20%"} position={"sticky"} top={"8vh"} py={"4rem"} height={"91vh"} flexDirection={"column"}>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>In this page</Box>
                <Box fontSize={"0.9rem"} mb={3} >Booking workflow</Box>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>> Direct Booking</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Description</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Service Selection</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Choose Staff</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Date and time</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Submit Information</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2} >Booking  workflow</Box>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>> External  Booking</Box>
                

            </Flex>
        </Flex>

    );
}



