import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    InputGroup,
    InputLeftElement,
    Input,
    chakra,
    InputRightElement,
    Heading,
} from '@chakra-ui/react';
import ReactFlow, { useNodesState, useEdgesState, addEdge } from 'reactflow';
import { MarkerType, Position } from 'reactflow';
import 'reactflow/dist/style.css';


import { FaSearch } from "react-icons/fa";
import { useCallback, useState } from 'react';
const CiSearchT = chakra(FaSearch);
export default function CreateCampaignDoc() {
    const initialNodes = [
        { id: '1', position: { x: 160, y: 30 }, data: { label: 'Fill Campaign Description' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"}, },
        { id: '2', position: { x: 160, y: 120 }, data: { label: 'Select Campaign Desired Reach' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"},  },
        { id: '3', position: { x: 160, y: 210 }, data: { label: 'Create campaign segments' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"},  },
        { id: '4', position: { x: 160, y: 300 }, data: { label: 'Create Campaign' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"},  },
        
        
        { id: '6', position: { x: 60, y: 450 },targetPosition: 'top', data: { label: 'Modify campaign' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"},   },
        { id: '7', position: { x: 270, y: 450 },targetPosition: 'top', data: { label: 'Buy more reach' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"},  },

        { id: '10', position: { x: 160, y: 370 }, data: { label: 'Is Reach Sufficient?' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"},  },
    ];
    const initialEdges = [
     
        { id: 'e4-5', source: '10', target: '5',markerEnd: { type: MarkerType.ArrowClosed, }, },

        { id: 'e77-5', source: '10', target: '7',markerEnd: { type: MarkerType.ArrowClosed, }, },
        { id: 'e10', source: '1', target: '2', markerEnd: { type: MarkerType.ArrowClosed, },},
        // {
        //     id: 'e10', source: '1', target: '10', markerEnd: {
        //         type: MarkerType.ArrowClosed,
        //         width: 20,
        //         height: 20,
        //         color: '#FF0072',
        //     },
        //     style: {
        //         strokeWidth: 2,
        //         stroke: '#FF0072',
        //     },
        // },
        // { id: 'e12', source: '10', target: '2', markerEnd: { type: MarkerType.ArrowClosed, }, },
        { id: 'e20', source: '2', target: '3', markerEnd: { type: MarkerType.ArrowClosed, }, },
        { id: 'e21', source: '3', target: '4', markerEnd: { type: MarkerType.ArrowClosed, }, },
        { id: 'e44', source: '4', target: '10', markerEnd: { type: MarkerType.ArrowClosed, },},
        { id: 'e5-6', source: '10', target: '6', type: 'optionalEdge', animated: true, label: 'optional' ,markerEnd: { type: MarkerType.ArrowClosed, },},
    ];
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [setEdges],
    );

    return (
        <Flex>
            <Box p={"3rem"} width={"80%"} pt={"1.5rem"} >
                <Box >Business > Marketing</Box>
                <Box fontSize={"1.5rem"} fontWeight={"bold"} mt={"1.5rem"} >Create Campaign</Box>
                <Text mb={6} borderBottom={"1px solid #d3d3d3"} pb={"18px"}>
                    A step-by-step guide is provided below for creating Campaign.
                </Text>


                <Text fontWeight={"bold"} mb={4}>Step 1: Accessing Campaign Creation</Text>
                <Text mb={4}>
                    After authentication, access the campaign creation process by either clicking on the "Start Marketing" button at the top or directly navigating to the route /dashboard/funktioner/markedsfoering/opret-kampagne. This will lead you to a page where, on the right, a small card displays the remaining reach notifications. Additional reach can be purchased by clicking the "BUY MORE" button.
                </Text>

                <Text fontWeight={"bold"} mb={4}>Step 2: Formulating Your Campaign</Text>
                <Text mb={4}>
                    On the left side of the page, you'll find a form to fill out with campaign details such as title, description, and image. These details will be displayed on the campaign card visible on the client's website. Furthermore, select target demographics for your campaign, including options like sex, location, and category. As you make selections, the total possible reach will adjust accordingly. At the bottom, set the time, date, and number of messages to be sent, with the option to select up to three segments.                </Text>

                <Text fontWeight={"bold"} mb={4}>Step 3: Creating the Campaign</Text>
                <Text mb={4}>
                    Once all campaign information is entered and options are reviewed, click on the "Create Campaign" button. This action will generate the campaign and direct you to the campaigns page, where your new campaign will be visible in the campaign list, and the total reach will be updated.                </Text>

                <Text fontWeight={"bold"} mb={4}>Step 4: Modifying Your Campaign</Text>
                <Text mb={4}>
                    To make modifications to your campaign, navigate to the campaign insights by clicking on "See Insights." Here, you'll find the option to modify your campaign details by clicking on the "Modify Campaign" button. Make the necessary adjustments as required.                </Text>


                <Text fontWeight={"bold"} size="md" textAlign={"center"} mt={"2rem"} pt={"2rem"} borderTop={"solid 1px #d3d3d3"} mb={"1rem"}>Create Campaign flowchart</Text>
                <Box h={"600px"} w={"60%"} margin={"auto"} border={"solid 2px #d3d3d3"} p={"2rem"} fontSize={"1.2rem"} color={"blue"}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                    />

                </Box>
                <Box fontSize={"1.5rem"} fontWeight={"bold"} mt={"1.5rem"} >Campaign Overview</Box>
                <Text mb={6} borderBottom={"1px solid #d3d3d3"} pb={"18px"}>
                    A guide to navigating past campaigns
                </Text>


                <Text fontWeight={"bold"} mb={4}>Access the campaign page</Text>
                <Text mb={4} ml={3}>
                    Begin by selecting the ALL campaigns tab from the main menu. This tab provides access to the complete history of campaigns initiated by the salon.    </Text>

                <Text fontWeight={"bold"} mb={4}>View campaign list </Text>
                <Text mb={4} ml={3}>
                    The page prominently displays a list of all previous campaigns. Each campaign entry includes key details and performance metrics, giving you a quick overview of the campaign history and outcomes.                </Text>



                <Text fontWeight={"bold"} mb={4}>Filter Campaign</Text>
                <Text mb={4} ml={3}>
                    <strong>By Text:</strong> Utilize the search functionality to filter campaigns based on keywords such as campaign name, specific results, or other related text criteria. This allows you to quickly find specific campaigns.
                </Text>

                <Text mb={4} ml={3}>
                    <strong>By Date: </strong> Use the date filter options to refine the list by selecting predefined time frames like the last week, last month, or custom date ranges. This helps in locating campaigns from specific periods.
                </Text>

                <Text fontWeight={"bold"} mb={4}>Analyse campaign details</Text>
                <Text mb={4} ml={3}>
                    Each row in the campaign list represents an individual campaign. The row typically includes the campaign's name, launch date, duration, and a brief summary of results. This structured format makes it easy to scan and identify campaigns of interest.
                </Text>
                <Text fontWeight={"bold"} mb={4}>Access Campaign Results</Text>
                <Text mb={4} ml={3}>
                    To delve deeper into the performance of a particular campaign, locate the See Results button positioned on the right side of each campaign entry. Clicking this button directs you to the detailed campaign page.                </Text>
                <Text fontWeight={"bold"} mb={4}>Modify Campaign</Text>
                <Text mb={4} ml={3}>
                    On the campaign’s dedicated page, you can view comprehensive results including detailed metrics, participant data, and overall impact. If the campaign is still active or valid, you have the option to modify certain aspects of it directly from this page. This might include adjusting target parameters, updating content, or changing end dates, ensuring your campaigns remain relevant and effective.                </Text>

  
            
            </Box>
            




            <Flex width={"20%"} position={"sticky"} top={"8vh"} py={"4rem"} height={"91vh"} flexDirection={"column"}>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>In this page</Box>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>> Create Campaign</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Accessing Campaign Creation</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Formulating Your Campaign</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Creating the Campaign</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Modifying Your Campaign</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Create campaign flow diagram</Box>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>> Campaign Overview</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Access the campaign page</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>View campaign list </Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Filter Campaign</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Analyse campaign details</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Access Campaign Results</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Modify Campaign</Box>
            </Flex>
        </Flex>

    );
}



