import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  Input,
  chakra,
  InputRightElement,
  Image,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { HiExternalLink } from "react-icons/hi";


import { FaSearch } from "react-icons/fa";
import { useState } from 'react';
import RegisterDoc from './RegisterDoc';
import CreateCampaignDoc from './CreateCampaignDoc';
import AIFunction from './AIFuncion';
import SettingsDoc from './SettingsDoc';
import EditProfile from './EditProfile';
import CampaignOverview from './CampaignOverview';
import RegisterClientDoc from './REgisterClientDoc';
import SaloonNavigation from './SaloonNavigation';
import BookingDoc from './BookingDoc';
import ClientSettingsDoc from './ClientSettingsDoc';
import SalonPage from './SalonPage';
const CiSearchT = chakra(FaSearch);
export default function MarketHome() {
  const { isOpen, onToggle } = useDisclosure();
  const [id, setId] = useState(0)
  const [tabId, setTabId] = useState(0); // State to manage active tab
  const suggestions = [
    { name: 'Business Settings', id: 3,tab:0 },
    { name: 'Edit business profile', id: 3,tab:0 },
    { name: 'Create campaign', id: 1,tab:0 },
    { name: 'Campaign overview', id: 1,tab:0 },
    { name: 'Business Login', id: 0,tab:0 },
    { name: 'Business Register', id: 0,tab:0 },
    { name: 'Edit client profile', id: 103,tab:1 },
    { name: 'Favorites', id: 103,tab:1 },
    { name: "Client's appointements", id: 103,tab:1 },
    { name: 'Edit client password', id: 103,tab:1 },
    { name: 'Client Salon page', id: 104,tab:1 },
    { name: 'Client Salon search', id: 101,tab:1 },
    { name: 'Direct Booking', id: 102,tab:1 },
    { name: 'External Booking', id: 102,tab:1 },
    { name: 'Client Login', id: 100,tab:1 },
    { name: 'Client Register', id: 100,tab:1 },
  ];
  const [query, setQuery] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value.length > 0) {
      const filtered = suggestions.filter(suggestion =>
        suggestion.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  };

  const handleSuggestionClick = (id,tab) => {
    setId(id);
    setTabId(tab)
    setQuery('');
    setFilteredSuggestions([]);
  };

  return (
    <Box >
      <Flex

        backgroundColor="#fafafa"
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        position={"sticky"}
        top={"0"}
        zIndex={99}
        align={'center'}>

        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }} h={"6vh"}>
          <Text
            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
            fontFamily={'heading'}
            color={useColorModeValue('gray.800', 'white')}>
            <Image h={"6vh"} src="https://www.bookinghero.io/assets/Logo/logo.webp" alt="" />
          </Text>
          <Box  w={"30%"} margin={"auto"}>
            <InputGroup w={"100%"} >
              <InputRightElement
                h={"2.5rem"}
                pointerEvents="none"
                children={<CiSearchT color="gray.300" />}
              />
              <Input
                type="text"
                placeholder="Search..."
                h={"2.5rem"}
                bg={"white"}
                value={query}
                onChange={handleInputChange}
              />
            </InputGroup>
            {filteredSuggestions.length > 0 && (
              <ul style={{borderRadius:"4px", width: '100%', margin: 'auto', marginTop:"1.2rem", listStyleType: 'none', padding: 0, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                {filteredSuggestions.map((suggestion,idx) => (
                  (idx>5? null:
                  <li
                    key={suggestion.id}
                    onClick={() => handleSuggestionClick(suggestion.id,suggestion.tab)}
                    style={{ cursor: 'pointer', padding: '0.5rem', background:"white", borderBottom: '1px solid #ccc' }}
                  >     
                      {suggestion.name}

                  </li>
                  )
                
                ))}
              </ul>
            )}
          </Box>
          {/* <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
              <DesktopNav />
            </Flex> */}

        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}>
          {/* <Button
              as={'a'}
              fontSize={'sm'}
              fontWeight={400}
              variant={'link'}
              href={'/signin'}>
              Sign In
            </Button>
            <Button
              as={'a'}
              display={{ base: 'none', md: 'inline-flex' }}
              fontSize={'sm'}
              fontWeight={600}
              color={'white'}
              bg={'teal.400'}
              href={'/register'}
              _hover={{
                bg: 'teal.300',
              }}>
              Sign Up
            </Button> */}
          <Button
            as={'a'}
            fontSize={'sm'}
            fontWeight={600}
            variant={'link'}
            color={"white"}
            bg={'red.400'}
            p={"0.5rem"}
            textDecoration={"none"}
            href={'/signin'}>
            Logout
          </Button>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>

      <Flex w={"100%"} >
        <Tabs index={tabId}  isFitted variant='enclosed' h={"91vh"} width={"20%"} borderRight={"1px solid #d3d3d3"} position={"sticky"} top={"8vh"} >
          <TabList>
            <Tab onClick={() => {setTabId(0);setId(0)}}>B2B</Tab>
            <Tab onClick={() => {setTabId(1);setId(100)}}>B2C</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {/* <Box h={"91vh"} width={"20%"} padding={"2rem"} borderRight={"1px solid #d3d3d3"} position={"sticky"} top={"8vh"} > */}
              <Box p={"0.8rem"} cursor={"pointer"} fontWeight={id == 0 && "bold"} color={id == 0 && "teal.500"} onClick={() => setId(0)}>Business Authentication</Box>
              <Box p={"0.8rem"} cursor={"pointer"} fontWeight={id == 1 && "bold"} color={id == 1 && "teal.500"} onClick={() => setId(1)}>Marketing</Box>
              {/* <Box p={"0.8rem"} cursor={"pointer"} fontWeight={id == 4 && "bold"} color={id == 4 && "teal.500"} onClick={() => setId(4)}>Overview of campaigns</Box> */}
              <Box p={"0.8rem"} cursor={"pointer"} fontWeight={id == 3 && "bold"} color={id == 3 && "teal.500"} onClick={() => setId(3)}>Configuration</Box>
              {/* <Box p={"0.8rem"} cursor={"pointer"} fontWeight={id == 2 && "bold"} color={id == 2 && "teal.500"} onClick={() => setId(2)}>Business Settings</Box> */}

              {/* <Box p={"0.8rem"} cursor={"pointer"} as="a"
                  href="https://ai.bookinghero.io"
                  display={"flex"}
                  alignItems={"center"}
                  target="_blank"
                  rel="noopener noreferrer">AI documentation <HiExternalLink style={{opacity:0.8,marginLeft:"6px"}} /> </Box>
   */}


              {/* </Box> */}
            </TabPanel>
            <TabPanel>
              <Box p={"0.8rem"} cursor={"pointer"} fontWeight={id == 100 && "bold"} color={id == 100 && "teal.500"} onClick={() => setId(100)}>Authentication</Box>
              <Box p={"0.8rem"} cursor={"pointer"} fontWeight={id == 101 && "bold"} color={id == 101 && "teal.500"} onClick={() => setId(101)}>Salon Search</Box>
              <Box p={"0.8rem"} cursor={"pointer"} fontWeight={id == 104 && "bold"} color={id == 104 && "teal.500"} onClick={() => setId(104)}>Salon Page</Box>
              <Box p={"0.8rem"} cursor={"pointer"} fontWeight={id == 102 && "bold"} color={id == 102 && "teal.500"} onClick={() => setId(102)}>Booking</Box>
              <Box p={"0.8rem"} cursor={"pointer"} fontWeight={id == 103 && "bold"} color={id == 103 && "teal.500"} onClick={() => setId(103)}>Configuration</Box>


            </TabPanel>

          </TabPanels>
        </Tabs>


        <Box w={"80%"}>
          {id == 0 && <Box><RegisterDoc /></Box>}
          {id == 1 && <Box><CreateCampaignDoc /></Box>}
          {id == 2 && <Box><SettingsDoc /></Box>}
          {id == 3 && <Box><EditProfile /></Box>}
          {id == 4 && <Box><CampaignOverview /></Box>}
          {id == 100 && <Box><RegisterClientDoc /></Box>}
          {id == 101 && <Box><SaloonNavigation /></Box>}
          {id == 102 && <Box><BookingDoc /></Box>}
          {id == 103 && <Box><ClientSettingsDoc /></Box>}
          {id == 104 && <Box><SalonPage /></Box>}
          <Flex alignItems={"center"} justifyContent={"center"} py={5} borderTop={"1px gray solid"}>
            <Box>© 2023 Alle rettigheder forbeholdes</Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ?? '#'}
                fontSize={'sm'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}>
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Link
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'pink.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          fontWeight={600}
          color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: 'Inspiration',
    children: [
      {
        label: 'Explore Design Work',
        subLabel: 'Trending Design to inspire you',
        href: '#',
      },
      {
        label: 'New & Noteworthy',
        subLabel: 'Up-and-coming Designers',
        href: '#',
      },
    ],
  },
  {
    label: 'Find Work',
    children: [
      {
        label: 'Job Board',
        subLabel: 'Find your dream design job',
        href: '#',
      },
      {
        label: 'Freelance Projects',
        subLabel: 'An exclusive list for contract work',
        href: '#',
      },
    ],
  },
  {
    label: 'Learn Design',
    href: '#',
  },
  {
    label: 'Hire Designers',
    href: '#',
  },
];