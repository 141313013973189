import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    InputGroup,
    InputLeftElement,
    Input,
    chakra,
    InputRightElement,
    Heading,
    background,
    color,
} from '@chakra-ui/react';
import ReactFlow, { useNodesState, useEdgesState, addEdge } from 'reactflow';

import 'reactflow/dist/style.css';


import { FaSearch } from "react-icons/fa";
import { useCallback, useState } from 'react';
const CiSearchT = chakra(FaSearch);
export default function RegisterDoc() {
    const initialNodes0 = [
        { id: '000', position: { x:350, y: 30 }, data: { label: 'Bussiness Authentication' },style: {fontSize:"14px",background:"#008080",fontFamily: "cursive",color:"white"}, },

        { id: '0', position: { x:50, y: 140 }, data: { label: 'Register' },style: {fontSize:"14px",background:"#66b2b2",fontFamily: "cursive",color:"white"}, },
        { id: '1', position: { x:50, y: 210 }, data: { label: 'Submit registration key' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"}, },
        { id: '2', position: { x: 50, y: 350 }, data: { label: 'Provide email & password' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"}, },
        { id: '3', position: { x: 50, y: 440 }, data: { label: 'Email confirmation' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"}, },
        { id: '4', position: { x: 50, y: 530 }, data: { label: 'provide the booking system key' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"}, },
        { id: '5', position: { x: 50, y: 620 }, data: { label: 'Finalize account creation' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"}, },
        // 
        { id: '10', position: { x: 270, y: 290 }, data: { label: 'Validate the key' }, style: {fontSize:"14px",background:"#fa8128",fontFamily: "cursive"}, },

        { id: '01', position: { x: 650, y: 140 }, data: { label: 'Login' }, style: {fontSize:"14px",background:"#66b2b2",fontFamily: "cursive",color:"white"}, },
        { id: '02', position: { x: 650, y: 240 }, data: { label: 'Filling login form' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"}, },
        { id: '05', position: { x: 650, y: 340 }, data: { label: 'Finalize login' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"}, },
    ];
    const initialEdges0 = [
        { id: 'e1-2', source: '000', target: '01', },
        { id: 'e2-3', source: '000', target: '0' },
        { id: 'e3-4', source: '0', target: '1' },
        { id: 'e4-5', source: '4', target: '5' },
        { id: 'e10', source: '1', target: '10' },
        { id: 'e12', source: '10', target: '2' },
        { id: 'e20', source: '2', target: '3' },
        { id: 'e21', source: '3', target: '4' },

        { id: '0e1-5', source: '01', target: '02' },
        { id: '0e4-5', source: '02', target: '05' },

    ];
    const [nodes0, setNodes0, onNodesChange0] = useNodesState(initialNodes0);
    const [edges0, setEdges0, onEdgesChange0] = useEdgesState(initialEdges0);

    const onConnect0 = useCallback(
        (params) => setEdges0((eds) => addEdge(params, eds)),
        [setEdges0],
    );
    const initialNodes = [

        { id: '1', position: { x:50, y: 30 }, data: { label: 'Submit registration key' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"}, },
        { id: '2', position: { x: 50, y: 170 }, data: { label: 'Provide email & password' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"}, },
        { id: '3', position: { x: 50, y: 270 }, data: { label: 'Email confirmation' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"}, },
        { id: '4', position: { x: 50, y: 350 }, data: { label: 'provide the booking system key' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"}, },
        { id: '5', position: { x: 50, y: 450 }, data: { label: 'Finalize account creation' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"}, },
        // 
        { id: '10', position: { x: 270, y: 110 }, data: { label: 'Validate the key' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"}, },
    ];
    const initialEdges = [
        // { id: 'e1-2', source: '1', target: '2', },
        // { id: 'e2-3', source: '1', target: '2' },
        // { id: 'e3-4', source: '1', target: '2' },
        { id: 'e4-5', source: '4', target: '5' },
        { id: 'e10', source: '1', target: '10' },
        { id: 'e12', source: '10', target: '2' },
        { id: 'e20', source: '2', target: '3' },
        { id: 'e21', source: '3', target: '4' }
    ];
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [setEdges],
    );
    const initialNodes2 = [
        { id: '2', position: { x: 80, y: 30 }, data: { label: 'Filling login form' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"}, },
        { id: '5', position: { x: 80, y: 120 }, data: { label: 'Finalize login' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"}, },
    ];
    const initialEdges2 = [
        { id: 'e4-5', source: '2', target: '5' },
    ];
    const [nodes2, setNodes2, onNodesChange2] = useNodesState(initialNodes2);
    const [edges2, setEdges2, onEdgesChange2] = useEdgesState(initialEdges2);

    const onConnect2 = useCallback(
        (params) => setEdges2((eds) => addEdge(params, eds)),
        [setEdges2],
    );

    return (
        <Flex>
            <Box p={"3rem"} width={"80%"} pt={"1.5rem"} >
                <Box >Business > Authentication</Box>
                <Box fontSize={"1.5rem"} fontWeight={"bold"} mt={"1.5rem"} >Business Authentication</Box>
                <Text mb={6} borderBottom={"1px solid #d3d3d3"} pb={"18px"}>
                        A step-by-step guide is provided below for registering/login business Saloon on the BookingHero platform.
                    </Text>
                    <Box h={"800px"} w={"100%"} margin={"auto"}  border={"solid 2px #d3d3d3"} p={"2rem"} fontSize={"1.2rem"} color={"blue"}>

                    <ReactFlow
                        nodes={nodes0}
                        edges={edges0}
                        onNodesChange={onNodesChange0}
                        onEdgesChange={onEdgesChange0}
                        onConnect={onConnect0}
                     
                    />
                    </Box>
                <Box>
                <Box fontSize={"1.5rem"} fontWeight={"bold"} mt={"2.5rem"} >Register</Box>
                <Text mb={6} borderBottom="1px solid #d3d3d3" pb="18px">
                </Text>
                    {/* Step 1: Verify Company Registration */}
                    <Text fontWeight={"bold"} mb={4}>Step 1: Submitting credentials</Text>
                    <Text mb={4}>
                        To register on the BookingHero business platform, you must be a registered Danish company with a valid registration number.
                        Alongside your email, account password we ask to provide the Saloon registration number. 
                        normally the Saloon should be autocompleted. If it is not listed
                        in our database, contact our support team for assistance.
                    </Text>

                    {/* Step 2: Confirmation and Validation */}
                    <Text fontWeight={"bold"} size="md" mb={4}>Step 2: Confirmation and Validation</Text>
                    <Text mb={4}>
                        Once your company registration is verified, you'll receive a confirmation email. Proceed to the next step upon receiving
                        this confirmation.
                    </Text>

                    {/* Step 3: Booking System Key */}
                    <Text fontWeight={"bold"} size="md" mb={4}>Step 3: Booking System Key</Text>
                    <Text mb={4}>
                        After confirmation, we request the booking system key. This key is provided by Admind after customer permission. It enables
                        access to the customer's database, allowing us to retrieve your salon information.
                    </Text>

                    {/* Step 4: Access the App */}
                    <Text fontWeight={"bold"} size="md" mb={4}>Step 4: Access the App</Text>
                    <Text mb={4}>
                        Once we have obtained the booking system key, you will get access to the BookingHero app. Your information will be displayed, and you
                        can modify or add any necessary details.
                    </Text>

            
                    <Text>
                        You are now a client on the BookingHero business platform. Welcome aboard!
                    </Text>
                </Box>
                <Text fontWeight={"bold"} size="md" textAlign={"center"} mt={"2rem"} pt={"2rem"} borderTop={"solid 1px #d3d3d3"} mb={"1rem"}>Business Registration flowchart</Text>
                <Box h={"600px"} w={"60%"} margin={"auto"}  border={"solid 2px #d3d3d3"} p={"2rem"} fontSize={"1.2rem"} color={"blue"}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                     
             
                    />
                    
                </Box>

                <Box fontSize="1.5rem" fontWeight="bold" mt="1.5rem">Login</Box>
                <Text mb={6} borderBottom="1px solid #d3d3d3" pb="18px">
                </Text>
                <Box>
                    {/* Step 1: Submitting Credentials */}
                    <Text fontWeight="bold" mb={4}>Step 1: Submitting Credentials</Text>
                    <Text mb={4}>
                        To login on the BookingHero, go to the login page. You will be redirected to a form where you need to provide your email and password.
                    </Text>

                  

                    {/* Step 2: Access the App */}
                    <Text fontWeight="bold" size="md" mb={4}>Step 2: Access the App</Text>
                    <Text mb={4}>
                        After validating your credentials, you will have full access to the application's functionalities.
                    </Text>


                </Box>
                <Text fontWeight={"bold"} size="md" textAlign={"center"} mt={"2rem"} pt={"2rem"} borderTop={"solid 1px #d3d3d3"} mb={"1rem"}>Bussiness login flowchart</Text>
                <Box h={"300px"} w={"40%"} margin={"auto"} border={"solid 2px #d3d3d3"} p={"2rem"} fontSize={"1.2rem"} color={"blue"}>
                    <ReactFlow
                        nodes={nodes2}
                        edges={edges2}
                        onNodesChange={onNodesChange2}
                        onEdgesChange={onEdgesChange2}
                        onConnect={onConnect2}


                    />

                </Box>


            </Box>
            <Flex width={"20%"} position={"sticky"} top={"8vh"} py={"4rem"} height={"91vh"} flexDirection={"column"}>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>In this page</Box>
                <Box fontSize={"0.9rem"} mb={3} >Authentication workflow</Box>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>> Registration</Box>
                <Box fontSize={"0.9rem"} mb={3}  ml={2}>Submitting credentials</Box>
                <Box fontSize={"0.9rem"} mb={3}  ml={2}>Confirmation and Validation</Box>
                <Box fontSize={"0.9rem"} mb={3}  ml={2}>Booking System Key</Box>
                <Box fontSize={"0.9rem"} mb={3}  ml={2}>Access the App</Box>
                <Box fontSize={"0.9rem"} mb={3}  ml={2} >Registration  workflow</Box>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>> Login</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Submitting Credentials</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Access the App</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Login workflow</Box>
                
            </Flex>
        </Flex>

    );
}



