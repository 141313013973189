import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  Input,
  chakra,
  InputRightElement,
  Image,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Card,
  CardBody,
  Heading,
  CardFooter,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { HiExternalLink } from "react-icons/hi";


import { FaSearch } from "react-icons/fa";
import { useState } from 'react';
import RegisterDoc from './RegisterDoc';
import CreateCampaignDoc from './CreateCampaignDoc';
import AIFunction from './AIFuncion';
import SettingsDoc from './SettingsDoc';
import EditProfile from './EditProfile';
import CampaignOverview from './CampaignOverview';
import RegisterClientDoc from './REgisterClientDoc';
import SaloonNavigation from './SaloonNavigation';
import marketImage from "../assets/market.png"
import labImage from "../assets/image.png"
import expressImage from "../assets/express.png"
const CiSearchT = chakra(FaSearch);
export default function Home() {
  const { isOpen, onToggle } = useDisclosure();
  const [id, setId] = useState(0)
  const [tabId, setTabId] = useState(0); // State to manage active tab
  return (
    <Flex h={"100vh"} justifyContent={"center"} alignItems={"center"}>
      <Card data-type='Card' overflow="hidden" variant="outline" width={"29%"} mx={"2%"} minh={"54%"} maxH={"fit-content"} display={"flex"}
      justifyContent={"space-between"} >
        <Image data-type='Image' src={marketImage} objectFit="cover" maxW='70%' margin={"auto"} mt={"2.5rem"}>
        </Image>
        <Stack data-type='Stack'>
          <CardBody data-type='CardBody' py={0}>
          
            <Text data-type='Text' py="2" textAlign={"justify"}>
            Bookinghero Market is an innovative online marketplace catering to the beauty and wellness industry. It serves as a one-stop destination for salon owners. Bookinghero Market provides tools for salon owners to execute marketing strategies effectively, enabling them to promote their services and engage with clients seamlessly. Bookinghero Market simplifies the procurement process, enabling salon owners to focus on delivering exceptional service to their clients.<br/><br/>
            </Text>
          </CardBody>
          <CardFooter data-type='CardFooter' pt={2}>
            <Button as={"a"} data-type='Button' type="button" variant="solid" colorScheme="teal" href='market' background={"teal.400"}>
              View Documentation
            </Button>
          </CardFooter>
        </Stack>
      </Card>
      <Card data-type='Card' overflow="hidden" variant="outline" width={"29%"} minh={"54%"} maxH={"fit-content"} mx={"2%"}  display={"flex"}
      justifyContent={"space-between"} >
        <Image data-type='Image' src={expressImage} objectFit="cover" maxW='70%' margin={"auto"} mt={"2.5rem"}>
        </Image>
        <Stack data-type='Stack'>
          <CardBody data-type='CardBody' py={0}>
         
            <Text data-type='Text' py="2" textAlign={"justify"} >
            Bookinghero Xpress is a cutting-edge platform designed to revolutionize the way salon owners manage their businesses, specifically catering to salons without an existing booking system. With intuitive features tailored specifically for the beauty and wellness industry, Bookinghero Xpress streamlines appointment booking and enhances customer engagement. Whether it's scheduling appointments, Bookinghero Xpress empowers salon owners to efficiently run their operations and deliver exceptional service to their clients.
            </Text>
          </CardBody>
          <CardFooter data-type='CardFooter' pt={2}>
            <Button as={"a"} data-type='Button' type="button" variant="solid" colorScheme="teal" href='express' background={"teal.400"}>
              View Documentation
            </Button>
          </CardFooter>
        </Stack>
      </Card>
      <Card data-type='Card' overflow="hidden" variant="outline" width={"29%"} minh={"54%"} maxH={"fit-content"} mx={"2%"} display={"flex"}
      justifyContent={"space-between"}>
        <Image data-type='Image' src={labImage} objectFit="cover" maxW='70%' margin={"auto"} mt={"2.5rem"}>
        </Image>
        <Stack data-type='Stack'>
          <CardBody data-type='CardBody' py={0} >

            <Text data-type='Text' pt="2" textAlign={"justify"}>
              Bookinghero Lab is an outsourced IT company based in Algeria, specializing in creating custom IT solutions for businesses around the world. With a strong blend of expertise and innovation, we deliver cost-effective, high-quality solutions that help companies optimize their technological infrastructure and drive growth. From developing complex systems to providing ongoing support, Bookinghero Lab is a reliable partner dedicated to meeting the unique needs of our clients and ensuring their success.
            </Text>
          </CardBody>
          <CardFooter data-type='CardFooter'  pt={2}>
            <Button as={"a"} data-type='Button'  type="button" variant="solid" colorScheme="teal" onClick={() => window.location.href = 'https://ai.bookinghero.io'} background={"teal.400"}>
              View Documentation
            </Button>
          </CardFooter>
        </Stack>
      </Card>

    </Flex>
  );
}

