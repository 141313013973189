import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    InputGroup,
    InputLeftElement,
    Input,
    chakra,
    InputRightElement,
    Heading,
} from '@chakra-ui/react';
import ReactFlow, { useNodesState, useEdgesState, addEdge } from 'reactflow';

import 'reactflow/dist/style.css';


import { FaSearch } from "react-icons/fa";

export default function SalonPage() {



    return (
        <Flex>
            <Box p={"3rem"} width={"80%"} pt={"1.5rem"} >
                <Box>
                    Client &gt; Salon Page
                </Box>
                <Box fontSize="1.5rem" fontWeight="bold" mt="1.5rem">
                    Salon Page
                </Box>
                <Text mb={6} borderBottom="1px solid #d3d3d3" pb="18px">
                    View Salon Descirption and details
                </Text>
                <Box>
                    {/* Search Functionality */}
                    <Text fontWeight="bold" mb={4}>Description</Text>
                    <Text mb={4}>
                        Access detailed salon information by clicking on a salon card or searching directly using the search bar.
                    </Text>

                    {/* Search Bar */}
                    <Text fontWeight="bold" size="md" mb={4}>Salon Services</Text>
                    <Text mb={4}>
                        View and select from a variety of services offered by the salon, including haircuts, coloring, styling, and more.
                    </Text>

                    {/* Region Dropdown Filter */}
                    <Text fontWeight="bold" size="md" mb={4}>Salon Staff</Text>
                    <Text mb={4}>
                        Learn about the professional staff at the salon, including their specialties and customer reviews.
                    </Text>

                    <Text fontWeight="bold" size="md" mb={4}>Salon Location</Text>
                    <Text mb={4}>
                        Find the salon's location displayed on Google Maps for easy navigation.
                    </Text>

                    <Text fontWeight="bold" size="md" mb={4}>Salon Schedule</Text>
                    <Text mb={4}>
                        Check the salon's hours of operation and available appointment slots.
                    </Text>

                    <Text fontWeight="bold" size="md" mb={4}>Salon Card</Text>
                    <Text mb={4}>
                        View a summary card on the right side showing the salon's rating, name, logo, and a button for booking appointments.
                    </Text>
                </Box>



            </Box>
            <Flex width={"20%"} position={"sticky"} top={"8vh"} py={"4rem"} height={"91vh"} flexDirection={"column"}>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>In this page</Box>
                <Box fontSize={"0.9rem"} mb={3} >Description</Box>
                <Box fontSize={"0.9rem"} mb={3} >Salon Services</Box>
                <Box fontSize={"0.9rem"} mb={3} >Salon staffs</Box>
                <Box fontSize={"0.9rem"} mb={3} >Salon Location</Box>
                <Box fontSize={"0.9rem"} mb={3} >Salon Location</Box>
                <Box fontSize={"0.9rem"} mb={3} >Salon card</Box>
            </Flex>
        </Flex>

    );
}



