import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    InputGroup,
    InputLeftElement,
    Input,
    chakra,
    InputRightElement,
    Heading,
} from '@chakra-ui/react';
import ReactFlow, { useNodesState, useEdgesState, addEdge } from 'reactflow';
import { MarkerType, Position } from 'reactflow';
import 'reactflow/dist/style.css';


import { FaSearch } from "react-icons/fa";
import { useCallback, useState } from 'react';
const CiSearchT = chakra(FaSearch);
export default function CampaignOverview() {
    const initialNodes = [
        { id: '1', position: { x: 200, y: 30 }, data: { label: 'Edit Profile' }, style: { fontSize: "12px" }, },
        { id: '2', position: { x: 10, y: 210 }, targetPosition: 'top', data: { label: 'Edit Location' }, style: { fontSize: "12px" }, },
        { id: '3', position: { x: 180, y: 210 }, targetPosition: 'top', data: { label: 'Edit General Infos' }, style: { fontSize: "12px" }, },


        { id: '6', position: { x: 100, y: 120 }, targetPosition: 'top', data: { label: 'Edit General' }, style: { fontSize: "12px" }, },
        { id: '7', position: { x: 310, y: 120 }, targetPosition: 'top', data: { label: ' add Social media' }, style: { fontSize: "12px" }, },

    ];
    const initialEdges = [
        { id: 'e4-5', source: '1', target: '6', markerEnd: { type: MarkerType.ArrowClosed, }, },
        { id: 'e77-5', source: '1', target: '7', markerEnd: { type: MarkerType.ArrowClosed, }, },
        { id: 'e20', source: '6', target: '2', markerEnd: { type: MarkerType.ArrowClosed, }, },
        { id: 'e21', source: '6', target: '3', markerEnd: { type: MarkerType.ArrowClosed, }, },
    ];
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [setEdges],
    );

    return (
        <Flex>
            <Box p={"3rem"} width={"80%"} pt={"1.5rem"} >
                <Box >Business > Campaign Overview</Box>
                <Box fontSize={"1.5rem"} fontWeight={"bold"} mt={"1.5rem"} >Campaign Overview</Box>
                <Text mb={6} borderBottom={"1px solid #d3d3d3"} pb={"18px"}>
                    A guide to navigating past campaigns
                </Text>


                <Text fontWeight={"bold"} mb={4}>Access the campaign page</Text>
                <Text mb={4} ml={3}>
                    Begin by selecting the ALL campaigns tab from the main menu. This tab provides access to the complete history of campaigns initiated by the salon.    </Text>

                <Text fontWeight={"bold"} mb={4}>View campaign list </Text>
                <Text mb={4} ml={3}>
                    The page prominently displays a list of all previous campaigns. Each campaign entry includes key details and performance metrics, giving you a quick overview of the campaign history and outcomes.                </Text>



                <Text fontWeight={"bold"} mb={4}>Filter Campaign</Text>
                <Text mb={4} ml={3}>
                    <strong>By Text:</strong> Utilize the search functionality to filter campaigns based on keywords such as campaign name, specific results, or other related text criteria. This allows you to quickly find specific campaigns.
                </Text>

                <Text mb={4} ml={3}>
                    <strong>By Date: </strong> Use the date filter options to refine the list by selecting predefined time frames like the last week, last month, or custom date ranges. This helps in locating campaigns from specific periods.
                </Text>

                <Text fontWeight={"bold"} mb={4}>Analyse campaign details</Text>
                <Text mb={4} ml={3}>
                    Each row in the campaign list represents an individual campaign. The row typically includes the campaign's name, launch date, duration, and a brief summary of results. This structured format makes it easy to scan and identify campaigns of interest.
                </Text>
                <Text fontWeight={"bold"} mb={4}>Access Campaign Results</Text>
                <Text mb={4} ml={3}>
                    To delve deeper into the performance of a particular campaign, locate the See Results button positioned on the right side of each campaign entry. Clicking this button directs you to the detailed campaign page.                </Text>
                <Text fontWeight={"bold"} mb={4}>Modify Campaign</Text>
                <Text mb={4} ml={3}>
                    On the campaign’s dedicated page, you can view comprehensive results including detailed metrics, participant data, and overall impact. If the campaign is still active or valid, you have the option to modify certain aspects of it directly from this page. This might include adjusting target parameters, updating content, or changing end dates, ensuring your campaigns remain relevant and effective.                </Text>

                {/* <Text fontWeight={"bold"} size="md" textAlign={"center"} mt={"2rem"} pt={"2rem"} borderTop={"solid 1px #d3d3d3"} mb={"1rem"}>Edit Profile flowchart</Text>
                <Box h={"360px"} w={"60%"} margin={"auto"} border={"solid 2px #d3d3d3"} p={"2rem"} fontSize={"1.2rem"} color={"blue"}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                    />

                </Box> */}
            </Box>




            <Flex width={"20%"} position={"sticky"} top={"8vh"} py={"4rem"} height={"91vh"} flexDirection={"column"}>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>In this page</Box>
                <Box fontSize={"0.9rem"} mb={3}>Access the campaign page</Box>
                <Box fontSize={"0.9rem"} mb={3}>View campaign list </Box>
                <Box fontSize={"0.9rem"} mb={3}>Filter Campaign</Box>
                <Box fontSize={"0.9rem"} mb={3}>Analyse campaign details</Box>
                <Box fontSize={"0.9rem"} mb={3}>Access Campaign Results</Box>
                <Box fontSize={"0.9rem"} mb={3}>Modify Campaign</Box>

            </Flex>
        </Flex>

    );
}



