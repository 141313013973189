import { useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowClick = () => setShowPassword(!showPassword);
  const diagonalLineStyle = {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'linear-gradient(to bottom left, transparent 49.5%, #036c5f 49.5%, #036c5f 51.5%, transparent 50.5%)',
    zIndex: 1,
    width: "100%",
    height:"100%"
  };
  return (

    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="#fafafa"
      justifyContent="center"
      alignItems="center"
      
    >
      {/* <Box style={diagonalLineStyle}>

      </Box> */}
      <Stack
        
        position={"relative"}
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
        w={"35%"}
      >

        <Heading color="teal.500" width={"100%"} textAlign={"left"} fontSize={"1.5em"}>BookingHero</Heading>
        <Box w={"100%"} my={"3rem"} position={"relative"} zIndex={99}
        boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
        borderRadius={"8px"}
      
        >
          <form>
            <Stack
              spacing={4}
              p="0.2rem"
              backgroundColor="white"
              boxShadow="md"
              borderRadius={"8px"}
            >
              <Box w={"80%"} textAlign={"left"} fontSize={"1.4em"} fontWeight={"bold"} margin={"auto"} marginTop={"3rem"} mb={"1.5rem"}>
                Register to BookingHero Handbook
              </Box>
              <FormControl w={"80%"} margin={"auto"} mb={"1.5rem"} >
                <InputGroup >
                  <InputLeftElement
                    h={"3rem"}
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input type="email" placeholder="email address" h={"3rem"} />
                </InputGroup>
              </FormControl>
              <FormControl w={"80%"} margin={"auto"} mb={"1.5rem"}>
                <InputGroup>
                  <InputLeftElement
                    h={"3rem"}
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    h={"3rem"}
                  />
                  <InputRightElement width="4.5rem" h={"3rem"}>
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              
              </FormControl>
              <FormControl w={"80%"} margin={"auto"} mb={"1.5rem"}>
                <InputGroup>
                  <InputLeftElement
                    h={"3rem"}
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    h={"3rem"}
                  />
                  <InputRightElement width="4.5rem" h={"3rem"}>
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              
              </FormControl>
              <Link href="/cards"  w={"80%"} margin={"auto"}>
              <Button
                h={"3rem"}
                w={"100%"}
                borderRadius={6}
                type="submit"
                variant="solid"
                colorScheme="teal"
                mb={"2rem"}
                as={"a"}
              >
                Register
              </Button></Link>
              <Flex background={"gray.100"} w={"100%"} h={"4rem"} justifyContent={"center"} alignItems={"center"}>
                Already have an account?{" "}
                <Link color="teal.500" href="/signin" marginLeft={"0.5em"}>
                  Login
                </Link>
              </Flex>
            </Stack>
          </form>

        </Box>

      </Stack>
      <Box >
        @bookinghero
      </Box>
    </Flex>
  );
};
export default Register;
