import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    InputGroup,
    InputLeftElement,
    Input,
    chakra,
    InputRightElement,
    Heading,
} from '@chakra-ui/react';
import ReactFlow, { useNodesState, useEdgesState, addEdge } from 'reactflow';

import 'reactflow/dist/style.css';


import { FaSearch } from "react-icons/fa";
import { useCallback, useState } from 'react';
const CiSearchT = chakra(FaSearch);
export default function SaloonNavigation() {
    const initialNodes = [
        { id: '2', position: { x: 80, y: 30 }, data: { label: 'Filling registration form' }, style: { fontSize: "12px" }, },
        { id: '3', position: { x: 80, y: 120 }, data: { label: 'Inputing the rreceived PIN code' }, style: { fontSize: "12px" }, },
        { id: '5', position: { x: 80, y: 230 }, data: { label: 'Finalize account creation' }, style: { fontSize: "12px" }, },
    ];
    const initialEdges = [
        { id: 'e4-5', source: '2', target: '3' },
        { id: 'e10', source: '3', target: '5' },
    ];
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [setEdges],
    );
    const initialNodes2 = [
        { id: '2', position: { x: 80, y: 30 }, data: { label: 'Filling login form' }, style: { fontSize: "12px" }, },
        { id: '5', position: { x: 80, y: 120 }, data: { label: 'Finalize login' }, style: { fontSize: "12px" }, },
    ];
    const initialEdges2 = [
        { id: 'e4-5', source: '2', target: '5' },
    ];
    const [nodes2, setNodes2, onNodesChange2] = useNodesState(initialNodes2);
    const [edges2, setEdges2, onEdgesChange2] = useEdgesState(initialEdges2);

    const onConnect2 = useCallback(
        (params) => setEdges2((eds) => addEdge(params, eds)),
        [setEdges2],
    );
    return (
        <Flex>
            <Box p={"3rem"} width={"80%"} pt={"1.5rem"} >
                <Box>Client > Salon Search</Box>
                <Box fontSize="1.5rem" fontWeight="bold" mt="1.5rem">Salon Search</Box>
                <Text mb={6} borderBottom="1px solid #d3d3d3" pb="18px">
                    Search salons with ease using an intuitive search interface.
                </Text>
                <Box>
                    {/* Search Functionality */}
                    <Text fontWeight="bold" mb={4}>Search Options</Text>
                    <Text mb={4}>
                        Accessible through the navigation bar on the home page, the Salon Search page offers two primary ways to find salons:
                        <Box ml={3}>1. Typing text for research.</Box>
                        <Box ml={3}>2. Clicking on a category of saloons.</Box>
                    </Text>

                    {/* Search Bar */}
                    <Text fontWeight="bold" size="md" mb={4}>Search Bar</Text>
                    <Text mb={4}>
                        At the top of the page, you'll find a search bar where you can look for salon names, categories, or places.
                    </Text>

                    {/* Region Dropdown Filter */}
                    <Text fontWeight="bold" size="md" mb={4}>Region Dropdown Filter</Text>
                    <Text mb={4}>
                        On the left side, a small region dropdown filter allows you to refine your search by selecting specific regions.
                    </Text>

                    {/* Salon Cards */}
                    <Text fontWeight="bold" size="md" mb={4}>Salon Cards</Text>
                    <Text mb={4}>
                        The page displays a list of cards representing different salons. Each card includes key information such as name, address, evaluation, and distance (if cookies are accepted).
                    </Text>

                    {/* Map with Markers */}
                    <Text fontWeight="bold" size="md" mb={4}>Map with Markers</Text>
                    <Text mb={4}>
                        On the right side, a dynamic map showcases various salon locations with markers. Each marker denotes a salon and displays its evaluation number. You can click on a marker to view its corresponding salon card.
                    </Text>

                    {/* Salon Page */}
                    <Text fontWeight="bold" size="md" mb={4}>Salon Page</Text>
                    <Text mb={4}>
                        Clicking on a salon card allows you to access more details about the salon on its dedicated page. Here, you can find comprehensive information and even book a reservation.
                    </Text>


                </Box>

                {/* <Text fontWeight={"bold"} size="md" textAlign={"center"} mt={"2rem"} pt={"2rem"} borderTop={"solid 1px #d3d3d3"} mb={"1rem"}>Client Registration flowchart</Text> */}
                {/* <Box h={"400px"} w={"40%"} margin={"auto"} border={"solid 2px #d3d3d3"} p={"2rem"} fontSize={"1.2rem"} color={"blue"}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}


                    />

                </Box> */}

            </Box>
            <Flex width={"20%"} position={"sticky"} top={"8vh"} py={"4rem"} height={"91vh"} flexDirection={"column"}>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>In this page</Box>
                <Box fontSize={"0.9rem"} mb={3} >Search Options</Box>
                <Box fontSize={"0.9rem"} mb={3} >Search Bar</Box>
                <Box fontSize={"0.9rem"} mb={3} >Region Filter</Box>
                <Box fontSize={"0.9rem"} mb={3} >Salon Cards</Box>
                <Box fontSize={"0.9rem"} mb={3} >Map</Box>
                <Box fontSize={"0.9rem"} mb={3} >Salon Page</Box>
            </Flex>
        </Flex>

    );
}



