import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    InputGroup,
    InputLeftElement,
    Input,
    chakra,
    InputRightElement,
    Heading,
    Image,
} from '@chakra-ui/react';
import ReactFlow, { useNodesState, useEdgesState, addEdge } from 'reactflow';
import { MarkerType, Position } from 'reactflow';
import 'reactflow/dist/style.css';

import EditP from "../assets/edit_profile.png"
import Pass from "../assets/edit_password.png"
import { FaSearch } from "react-icons/fa";
import { useCallback, useState } from 'react';
const CiSearchT = chakra(FaSearch);
export default function ClientSettingsDoc() {

    const initialNodes00 = [
        { id: '00', position: { x: 440, y: 30 }, sourcePosition: 'bottom', data: { label: 'Configuration' }, style: { fontSize: "14px", background: "#008080", fontFamily: "cursive", color: "white" }, },

        { id: 'e1', position: { x: 30, y: 140 }, sourcePosition: 'left', data: { label: 'Edit Profile' }, style: { fontSize: "14px", background: "#66b2b2", fontFamily: "cursive", color: "white" }, },
        { id: 'e11', position: { x: 60, y: 240 }, targetPosition: 'left', data: { label: 'Name' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: 'e12', position: { x: 60, y: 300 }, targetPosition: 'left', data: { label: 'address' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: 'e13', position: { x: 60, y: 360 }, targetPosition: 'left', data: { label: 'Phone' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },

        { id: 'f8', position: { x: 280, y: 140 }, sourcePosition: 'left', data: { label: 'Favorites' }, style: { fontSize: "14px", background: "#66b2b2", fontFamily: "cursive", color: "white" }, },
        { id: 'f81', position: { x: 290, y: 240 }, targetPosition: 'left', data: { label: 'Revisit Salon' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: 'f82', position: { x: 290, y: 300 }, targetPosition: 'left', data: { label: 'Remove Salon' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },


        { id: '1', position: { x: 540, y: 140 }, data: { label: 'Appointements' }, style: { fontSize: "14px", background: "#66b2b2", fontFamily: "cursive", color: "white" }, },
        { id: '2', position: { x: 480, y: 280 }, data: { label: 'Upcoming' }, style: { fontSize: "14px", background: "#e1e1e1", fontFamily: "cursive" }, },
        { id: '3', position: { x: 640, y: 280 }, data: { label: 'Completed' }, style: { fontSize: "14px", background: "#9ae6b4", fontFamily: "cursive" }, },
        { id: '4', position: { x: 800, y: 280 }, data: { label: 'Canceled' }, style: { fontSize: "14px", background: "#feb2b2", fontFamily: "cursive" }, },
        { id: '5', position: { x: 800, y: 380 }, data: { label: 'Rebooking' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '6', position: { x: 640, y: 380 }, data: { label: 'Feedback' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '7', position: { x: 480, y: 380 }, data: { label: 'Modify' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },


        { id: 's1', position: { x: 780, y: 140 }, data: { label: 'Edit password' }, style: { fontSize: "14px", background: "#66b2b2", fontFamily: "cursive", color: "white" }, },
    ];
    const initialEdges00 = [
        { id: 'e4-d5', source: '00', target: '1', markerEnd: { type: MarkerType.ArrowClosed, }, },
        { id: '2e4-d5', source: '00', target: 's1', markerEnd: { type: MarkerType.ArrowClosed, }, },
        { id: '3e4-d5', source: '00', target: 'f8', markerEnd: { type: MarkerType.ArrowClosed, }, },
        { id: '4e4-d5', source: '00', target: 'e1', markerEnd: { type: MarkerType.ArrowClosed, }, },

        { id: 'ee4-5', source: 'e1', target: 'e12' },
        { id: 'ee4d-5', source: 'e1', target: 'e13' },
        { id: 'ee4k-5', source: 'e1', target: 'e11' },

        { id: 'se4-5l', source: 'f8', target: 'f81' },
        { id: 'se4-]5', source: 'f8', target: 'f82' },

        { id: 'e4-5', source: '1', target: '2' },
        { id: 'e4-5', source: '1', target: '3' },
        { id: 'e10', source: '1', target: '4' },
        { id: 'e12', source: '2', target: '7' },
        { id: 'e22', source: '3', target: '5' },
        { id: 'e20', source: '3', target: '6' },
        { id: 'e21', source: '4', target: '5' },


    ];
    const [nodes00, setNodes00, onNodesChange00] = useNodesState(initialNodes00);
    const [edges00, setEdges00, onEdgesChange00] = useEdgesState(initialEdges00);

    const onConnect00 = useCallback(
        (params) => setEdges00((eds) => addEdge(params, eds)),
        [setEdges00],
    );





    const initialNodes = [
        { id: '1', position: { x: 300, y: 60 }, data: { label: 'Appointements' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '2', position: { x: 100, y: 200 }, data: { label: 'Upcoming' }, style: { fontSize: "14px", background: "#e1e1e1", fontFamily: "cursive" }, },
        { id: '3', position: { x: 300, y: 200 }, data: { label: 'Completed' }, style: { fontSize: "14px", background: "#9ae6b4", fontFamily: "cursive" }, },
        { id: '4', position: { x: 500, y: 200 }, data: { label: 'Canceled' }, style: { fontSize: "14px", background: "#feb2b2", fontFamily: "cursive" }, },
        { id: '5', position: { x: 500, y: 300 }, data: { label: 'Rebooking' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '6', position: { x: 300, y: 300 }, data: { label: 'Feedback' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },
        { id: '7', position: { x: 100, y: 300 }, data: { label: 'Modify' }, style: { fontSize: "14px", background: "#b2d8d8", fontFamily: "cursive" }, },

    ];
    const initialEdges = [
        { id: 'e4-5', source: '1', target: '2' },
        { id: 'e4-5', source: '1', target: '3' },
        { id: 'e10', source: '1', target: '4' },
        { id: 'e12', source: '2', target: '7' },
        { id: 'e22', source: '3', target: '5' },
        { id: 'e20', source: '3', target: '6' },
        { id: 'e21', source: '4', target: '5' }
    ];
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [setEdges],
    );





    return (
        <Flex>
            <Box p="3rem" width="80%" pt="1.5rem">
                <Box>Client > Configuration</Box>
                <Box fontSize="1.5rem" fontWeight="bold" mt="1.5rem">Configuration</Box>
                <Text mb={6} borderBottom="1px solid #d3d3d3" pb="18px">
                    Centralized control for updating information and configuring account settings.
                </Text>

                <Text fontWeight="bold" mb={4}>Description</Text>
                <Text mb={4}>
                    From the top right menu displaying your name, you will find several configuration options, detailed on this page.
                </Text>
                <Box h="550px" w="100%" margin="auto" border="2px solid #d3d3d3" p="1rem" fontSize="1.2rem" color="blue">
                    <ReactFlow
                        nodes={nodes00}
                        edges={edges00}
                        onNodesChange={onNodesChange00}
                        onEdgesChange={onEdgesChange00}
                        onConnect={onConnect00}
                    />
                </Box>
                <Text mb={6} borderBottom="1px solid #d3d3d3" pb="18px"></Text>
                <Box fontSize="1.5rem" fontWeight="bold" mt="1.5rem" mb="1.2rem">Edit Profile</Box>
                <Text mb={4}>
                    A form where the client can modify their information such as address and phone number, as shown in the next figure.
                    <Image src={EditP} w="50%" margin="auto" />
                </Text>
                <Text mb={6} borderBottom="1px solid #d3d3d3" pb="18px"></Text>
                <Box fontSize="1.5rem" fontWeight="bold" mt="1.5rem" mb="1.2rem">Favorites</Box>
                <Text mb={6} pb="18px">
                    View and manage your favorite salons. You can remove a salon from favorites or visit its page to book again.
                </Text>

                <Text mb={6} borderBottom="1px solid #d3d3d3" pb="18px"></Text>
                <Box fontSize="1.5rem" fontWeight="bold" mt="1.5rem" mb="1.2rem">Appointments</Box>

                <Text fontWeight="bold" mb={4}>Description</Text>
                <Text mb={4}>
                    This page shows all your appointments: completed, canceled, or upcoming. Each type will be detailed next.
                </Text>
                <Text fontWeight="bold" mb={4}>Upcoming Appointment</Text>
                <Text mb={4}>
                    The gray cards represent upcoming appointments. Clicking on them will display a card with salon information and the appointment schedule, allowing you to modify the appointment time, staff, or cancel it.
                </Text>
                <Text fontWeight="bold" mb={4}>Completed Appointment</Text>
                <Text mb={4}>
                    The green cards represent completed appointments. Clicking on them will display a card with salon and appointment information, allowing you to rebook with the same salon or give feedback.
                </Text>
                <Text fontWeight="bold" mb={4}>Canceled Appointment</Text>
                <Text mb={4}>
                    The red cards represent canceled appointments. Clicking on them will display salon and appointment information, with an option to book again.
                </Text>
                <Box h="450px" w="80%" margin="auto" border="2px solid #d3d3d3" p="1rem" fontSize="1.2rem" color="blue">
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                    />
                </Box>
                <Text mb={6} borderBottom="1px solid #d3d3d3" pb="18px"></Text>
                <Box fontSize="1.5rem" fontWeight="bold" mt="1.5rem" mb="1.2rem">Modify Password</Box>
                
                <Text mb={6} pb="18px">
                    On this page, you will find a form with three fields where you can enter your old password and set a new one.
                    <Image src={Pass} w="50%" margin="auto" />
                </Text>
            </Box>





            <Flex width={"20%"} position={"sticky"} top={"8vh"} py={"4rem"} height={"91vh"} flexDirection={"column"}>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>In this page</Box>
                <Box fontSize={"0.9rem"} mb={3}>Configuration workflow</Box>
                <Box fontSize={"0.9rem"} mb={3}>Description</Box>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>> Edit Profile</Box>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>> Favorites</Box>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>> Appointements</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Description</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Upcoming appointement</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Completed appointement</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Canceled appointement</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Appointements workflow</Box>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>> Edit Password</Box>




            </Flex>
        </Flex>

    );
}



