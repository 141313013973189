import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    InputGroup,
    InputLeftElement,
    Input,
    chakra,
    InputRightElement,
    Heading,
} from '@chakra-ui/react';
import ReactFlow, { useNodesState, useEdgesState, addEdge } from 'reactflow';

import 'reactflow/dist/style.css';


import { FaSearch } from "react-icons/fa";
import { useCallback, useState } from 'react';
const CiSearchT = chakra(FaSearch);
export default function RegisterClientDoc() {

    const initialNodesall = [
        { id: '0', position: { x: 230, y: 30 }, data: { label: 'Client Authentication' },style: {fontSize:"14px",background:"#008080",fontFamily: "cursive",color:"white"},  },

        { id: '1', position: { x: 80, y: 130 }, data: { label: 'Register' }, style: {fontSize:"14px",background:"#66b2b2",fontFamily: "cursive",color:"white"},  },
        { id: '2', position: { x: 80, y: 200 }, data: { label: 'Filling registration form' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"},  },
        { id: '3', position: { x: 80, y: 300 }, data: { label: 'Inputing the rreceived PIN code' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"},  },
        { id: '5', position: { x: 80, y: 380 }, data: { label: 'Finalize account creation' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"},  },
   
        { id: '21', position: { x: 380, y: 130 }, data: { label: 'Login' },style: {fontSize:"14px",background:"#66b2b2",fontFamily: "cursive",color:"white"},  },
        { id: '22', position: { x: 380, y: 210 }, data: { label: 'Filling login form' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"},  },
        { id: '52', position: { x: 380, y: 290 }, data: { label: 'Finalize login' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"},  },
   
    ];
    const initialEdgesall = [
        { id: 'e4-lk5', source: '0', target: '1' },
        { id: 'e4-lkp5', source: '0', target: '21' },
        { id: 'e4-lk5', source: '1', target: '2' },
        { id: 'e4-lkp5', source: '21', target: '22' },
        { id: 'e4-5', source: '2', target: '3' },
        { id: 'e10', source: '3', target: '5' },
        { id: 'e4-55', source: '22', target: '52' },
    ];
    const [nodesall, setNodesall, onNodesChangeall] = useNodesState(initialNodesall);
    const [edgesall, setEdgesall, onEdgesChangeall] = useEdgesState(initialEdgesall);

    const onConnectall = useCallback(
        (params) => setEdgesall((eds) => addEdge(params, eds)),
        [setEdgesall],
    );

    const initialNodes = [
        { id: '2', position: { x: 80, y: 30 }, data: { label: 'Filling registration form' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"},  },
        { id: '3', position: { x: 80, y: 120 }, data: { label: 'Inputing the rreceived PIN code' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"},  },
        { id: '5', position: { x: 80, y: 230 }, data: { label: 'Finalize account creation' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"},  },

     
    ];
    const initialEdges = [
        { id: 'e4-5', source: '2', target: '3' },
        { id: 'e10', source: '3', target: '5' },
    ];
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [setEdges],
    );
    const initialNodes2 = [
        { id: '2', position: { x: 80, y: 30 }, data: { label: 'Filling login form' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"},  },
        { id: '5', position: { x: 80, y: 120 }, data: { label: 'Finalize login' }, style: {fontSize:"14px",background:"#b2d8d8",fontFamily: "cursive"},  },
    ];
    const initialEdges2 = [
        { id: 'e4-5', source: '2', target: '5' },
    ];
    const [nodes2, setNodes2, onNodesChange2] = useNodesState(initialNodes2);
    const [edges2, setEdges2, onEdgesChange2] = useEdgesState(initialEdges2);

    const onConnect2 = useCallback(
        (params) => setEdges2((eds) => addEdge(params, eds)),
        [setEdges2],
    );
    return (
        <Flex>
            <Box p={"3rem"} width={"80%"} pt={"1.5rem"} >
                <Box>Client > Authentication</Box>
                <Box fontSize="1.5rem" fontWeight="bold" mt="1.5rem">Client Authentication</Box>
                <Text mb={6} borderBottom="1px solid #d3d3d3" pb="18px">
                    A step-by-step guide is provided below for client authentication.
                </Text>
                                <Text fontWeight={"bold"} size="md" textAlign={"center"} mt={"2rem"} pt={"2rem"} borderTop={"solid 1px #d3d3d3"} mb={"1rem"}>Client Authentication workflow</Text>
                <Box h={"600px"} w={"70%"} margin={"auto"} border={"solid 2px #d3d3d3"} p={"2rem"} fontSize={"1.2rem"} color={"blue"}>
                    <ReactFlow
                        nodes={nodesall}
                        edges={edgesall}
                        onNodesChange={onNodesChangeall}
                        onEdgesChange={onEdgesChangeall}
                        onConnect={onConnectall}


                    />

                </Box>
                <Box fontSize="1.5rem" fontWeight="bold" mt="1.5rem">Client Registration</Box>
                <Text mb={6} borderBottom="1px solid #d3d3d3" pb="18px">
                    A step-by-step guide is provided below for registration as a client for BookingHero.
                </Text>
                <Box>
                    {/* Step 1: Submitting Credentials */}
                    <Text fontWeight="bold" mb={4}>Step 1: Submitting Credentials</Text>
                    <Text mb={4}>
                        To register on the BookingHero Client platform, go to the registration page by selecting "Register" from the dropdown menu. You will be redirected to a form where you need to provide your email, password, and name.
                        Note that registration is only required for booking. All other navigation and search functionalities are accessible without registration.
                    </Text>

                    {/* Step 2: Confirmation and Validation */}
                    <Text fontWeight="bold" size="md" mb={4}>Step 2: Confirmation and Validation</Text>
                    <Text mb={4}>
                        After submitting your information, you will receive a pin code via email. Enter this pin code to complete the registration process.
                    </Text>

                    {/* Step 3: Access the App */}
                    <Text fontWeight="bold" size="md" mb={4}>Step 3: Access the App</Text>
                    <Text mb={4}>
                        Once the confirmation step is completed, you will have full access to the application's functionalities.
                    </Text>


                </Box>

                <Text fontWeight={"bold"} size="md" textAlign={"center"} mt={"2rem"} pt={"2rem"} borderTop={"solid 1px #d3d3d3"} mb={"1rem"}>Client Registration flowchart</Text>
                <Box h={"400px"} w={"40%"} margin={"auto"} border={"solid 2px #d3d3d3"} p={"2rem"} fontSize={"1.2rem"} color={"blue"}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}


                    />

                </Box>


                <Box fontSize="1.5rem" fontWeight="bold" mt="1.5rem">Client Login</Box>
                <Text mb={6} borderBottom="1px solid #d3d3d3" pb="18px">
                    A step-by-step guide is provided below for login into BookingHero.
                </Text>
                <Box>
                    {/* Step 1: Submitting Credentials */}
                    <Text fontWeight="bold" mb={4}>Step 1: Submitting Credentials</Text>
                    <Text mb={4}>
                        To login on the BookingHero, go to the login page by selecting "Login" from the dropdown menu. You will be redirected to a form where you need to provide your email and password
                        Note that login is only required for booking. All other navigation and search functionalities are accessible without login.
                    </Text>

                  

                    {/* Step 2: Access the App */}
                    <Text fontWeight="bold" size="md" mb={4}>Step 2: Access the App</Text>
                    <Text mb={4}>
                        After validating your credentials, you will have full access to the application's functionalities.
                    </Text>


                </Box>

                <Text fontWeight={"bold"} size="md" textAlign={"center"} mt={"2rem"} pt={"2rem"} borderTop={"solid 1px #d3d3d3"} mb={"1rem"}>Client Registration flowchart</Text>
                <Box h={"300px"} w={"40%"} margin={"auto"} border={"solid 2px #d3d3d3"} p={"2rem"} fontSize={"1.2rem"} color={"blue"}>
                    <ReactFlow
                        nodes={nodes2}
                        edges={edges2}
                        onNodesChange={onNodesChange2}
                        onEdgesChange={onEdgesChange2}
                        onConnect={onConnect2}


                    />

                </Box>

            </Box>
            <Flex width={"20%"} position={"sticky"} top={"8vh"} py={"4rem"} height={"91vh"} flexDirection={"column"}>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>In this page</Box>
                <Box fontSize={"0.9rem"} mb={3} >Authentication workflow</Box>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>> Registration</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Submitting Credentials</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Confirmation and Validation</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Access the App</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Workflow</Box>
                <Box fontSize={"0.9rem"} mb={3} fontWeight={"bold"}>> Login</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Submitting Credentials</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Access the App</Box>
                <Box fontSize={"0.9rem"} mb={3} ml={2}>Workflow</Box>
            </Flex>
        </Flex>

    );
}



