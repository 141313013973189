import React, { useState } from 'react';
import {
  ChakraProvider,
  Box,
  theme,
} from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// Import your components for each route
import Home from './components/Home';
import SignIn from './components/SignIn';
import Register from './components/Register';
import MarketHome from './components/MarketHome';
import XpressHome from './components/XpressHome';

function App() {
  const [logged,setLogged]=useState(false)
  return (
    <ChakraProvider theme={theme}>
   <Router>
      <Routes>
        <Route path="/cards" element={<Home />} />
        <Route path="/" element={<Navigate to="/signin" />} />
        <Route path="/market" element={<MarketHome />} />
        <Route path="/express" element={<XpressHome />} />
        <Route path="/signin" element={<SignIn  />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </Router>
    </ChakraProvider>
  );
}

export default App;
